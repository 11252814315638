import { FC, ReactNode } from "react";

import { Row } from "src/ui/box";
import { ChevronDownIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";

import { SelectComponentProps } from "../types";

interface ContainerProps extends SelectComponentProps {
  children: ReactNode;
}

export const Container: FC<ContainerProps> = ({ children, disabled, error, isOpen, loading }) => (
  <Row
    sx={{
      position: "relative",
      alignItems: "center",
      px: 3,
      border: "small",
      borderColor: error ? "red" : isOpen ? "primary" : undefined,
      borderRadius: 1,
      height: "32px",
      flexShrink: 0,
      justifyContent: "space-between",
      transition: "100ms border-color",
      bg: disabled ? "base.1" : "white",
      color: disabled ? "base.5" : undefined,
      ":hover:not(:disabled):not(:active):not(:focus)": {
        borderColor: isOpen ? undefined : "base.3",
      },
      flex: 1,
      whiteSpace: "nowrap",
    }}
  >
    {children}
    {loading ? <Spinner size={12} sx={{ ml: 2 }} /> : <ChevronDownIcon color={isOpen ? "primary" : "base.4"} sx={{ ml: 2 }} />}
  </Row>
);
