import { FC, useState } from "react";

import { capitalize } from "lodash";
import { useToasts } from "react-toast-notifications2";
import { Text, ThemeUIStyleObject } from "theme-ui";

import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  label: string;
  text?: React.ReactNode;
  sx?: ThemeUIStyleObject;
};

export const DeleteConfirmationModal: FC<Readonly<Props>> = ({ isOpen, onClose, label, onDelete, text, sx }) => {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    await onDelete();

    setLoading(false);
    onClose();

    addToast(`${capitalize(label)} deleted successfully!`, {
      appearance: "success",
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loading} variant="red" onClick={handleDelete}>
            Delete
          </Button>
        </>
      }
      isOpen={isOpen}
      sx={{ width: "500px", ...sx }}
      title={`Delete ${label}`}
      onClose={onClose}
    >
      {text ? text : <Text>Are you sure you want to delete this {label}? You won't be able to undo this.</Text>}
    </Modal>
  );
};
