import { FC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { ResourcePermissionGrant } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { Banner } from "src/ui/banner";
import { Link } from "src/ui/link";

export const OverageBanner: FC = () => {
  const { workspace, hasPermissions } = useUser();
  const isBillingPage = location.pathname.endsWith("/settings/billing");
  const { data: entitlementsData, isLoading: loadingEntitlements } = useEntitlements(!isBillingPage);
  const authorized = hasPermissions([{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }]);
  const { appShowOverageBanner } = useFlags();

  if (
    !appShowOverageBanner ||
    isBillingPage ||
    !authorized ||
    !workspace ||
    loadingEntitlements ||
    !entitlementsData.overage.overage
  ) {
    return null;
  }

  analytics.track("Overage Banner Shown", {
    workspace_id: workspace.id,
    authorized,
    ...entitlementsData.overage,
  });

  const onContactClick = () => {
    analytics.track("Overage Banner CTA Clicked", {
      workspace_id: workspace.id,
      authorized,
      ...entitlementsData.overage,
    });
  };

  return (
    <Banner sx={{ py: 3, bg: "indigos.3" }}>
      <Text sx={{ fontWeight: "bold", color: "white", fontSize: 1, mx: 4 }}>
        This workspace has exceeded the number of destinations allowed on its current plan. Please{" "}
        <Link
          sx={{ color: "yellow", "&:hover": { color: "yellow", textDecoration: "underline" } }}
          to="/settings/billing"
          onClick={onContactClick}
        >
          click here
        </Link>{" "}
        to upgrade.
      </Text>
    </Banner>
  );
};
