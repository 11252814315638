import { FC, useState, useEffect } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { GitCredentials, useGithubBranchesQuery, useGithubRepositoriesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";

interface Props {
  credentials: GitCredentials | undefined;
}

export const GitFields: FC<Readonly<Props>> = ({ credentials }) => {
  const { register, control, watch } = useFormContext();

  const [error, setError] = useState<string>("");
  const repository = watch("repository");

  const isGitHub = (credentials?.type === "github" || credentials?.type === "github_app") && !credentials.tunnel_id;

  const { data: githubRepos, isLoading: githubRepoLoading } = useGithubRepositoriesQuery(
    {
      credentialId: String(credentials?.id),
    },
    {
      select: (data) => data.getGithubRepositories,
      enabled: isGitHub,
      onError: (error) => setError(error?.message),
    },
  );

  const githubRepoOptions = githubRepos?.map((repo) => ({
    label: repo.fullName,
    value: repo.htmlUrl,
  }));

  const selectedGithubRepo = githubRepos?.find((repo) => repo.htmlUrl === repository);

  const { data: githubBranches, isLoading: githubBranchLoading } = useGithubBranchesQuery(
    {
      credentialId: String(credentials?.id),
      repo: selectedGithubRepo?.fullName ?? "",
    },
    {
      select: (data) => data.getGithubBranches,
      enabled: Boolean(isGitHub && selectedGithubRepo?.fullName),
      onError: (error) => setError(error?.message),
    },
  );

  const githubBranchOptions = githubBranches?.map((branch) => ({
    label: branch.name,
    value: branch.name,
  }));

  useEffect(() => {
    setError("");
  }, [credentials?.type]);

  return (
    <>
      <Field
        description={
          credentials?.type === "github" ? undefined : "Please use the full link of the git repository, including https."
        }
        error={error}
        label="Repository"
      >
        {isGitHub && githubRepoOptions ? (
          <Controller
            control={control}
            name="repository"
            render={({ field }) => (
              <Select
                isLoading={githubRepoLoading}
                options={githubRepoOptions}
                placeholder="Select a repository..."
                value={field?.value}
                onChange={(selected) => {
                  field.onChange(selected?.value);
                }}
              />
            )}
          />
        ) : (
          <Input {...register("repository")} />
        )}
      </Field>

      <Field label="Branch">
        {isGitHub && githubBranchOptions ? (
          <Controller
            control={control}
            name="branch"
            render={({ field }) => (
              <Select
                isLoading={githubBranchLoading}
                options={githubBranchOptions}
                placeholder="Select a branch..."
                value={field?.value}
                onChange={(selected) => {
                  field.onChange(selected?.value);
                }}
              />
            )}
          />
        ) : (
          <Input {...register("branch")} />
        )}
      </Field>
    </>
  );
};
