import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ComplianceIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M12,24C8.5,24,2,20.7,2,12.8C2,10.6,2,9,2,7.8c-0.1-3-0.1-3.7,0.8-4.5C3.6,2.5,7.5,0,12,0
			c4.5,0,8.4,2.5,9.3,3.3c0.8,0.8,0.8,1.5,0.8,4.5c0,1.2,0,2.8,0,5C22,20.7,15.5,24,12,24z M12,2.3C8.3,2.3,4.9,4.4,4.4,5
			C4.2,5.2,4.2,6.4,4.3,7.8c0,1.2,0.1,2.8,0.1,5c0,6.8,5.6,8.9,7.7,8.9s7.7-2.1,7.7-8.9c0-2.2,0-3.8,0.1-5c0-1.4,0-2.6-0.1-2.8
			C19.1,4.4,15.7,2.3,12,2.3z"
      />
      <path
        d="M11.2,15L11.2,15c-0.3,0-0.6-0.1-0.8-0.3l-2.2-2.2c-0.4-0.5-0.4-1.2,0-1.6c0.4-0.4,1.2-0.4,1.6,0l1.4,1.4
			l3.7-3.7c0.4-0.4,1.2-0.4,1.6,0c0.4,0.4,0.4,1.2,0,1.6L12,14.6C11.8,14.9,11.5,15,11.2,15z"
      />
    </Icon>
  );
};
