import { FC, useState } from "react";

import { isEqual } from "lodash";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text, Flex } from "theme-ui";

import { SidebarForm } from "src/components/page";
import { Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { UseSourceResult } from "src/utils/sources";

import { useUpdateSyncMutation } from "../../graphql";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { Field } from "../../ui/field";
import { Permission } from "../permission";

type Source = NonNullable<UseSourceResult["data"]>;

type WarehouseHistoryConfig = {
  tablesToWrite: {
    changelog: boolean;
    snapshot: boolean;
    syncRuns: boolean;
  };
};

type WarehouseSyncLogsConfig = {
  config: WarehouseHistoryConfig;
  userId: string | undefined;
  id: string | undefined;
  source: Source | undefined;
  model?: {
    visual_query_parent_id?: string;
  };
};

export const WarehouseSyncLogs: FC<Readonly<WarehouseSyncLogsConfig>> = ({ id, userId, config, source, model }) => {
  const { addToast } = useToasts();

  const [warehouseSyncLogsConfig, setWarehouseSyncLogsConfig] = useState<WarehouseHistoryConfig>(config);
  const sourceSyncLogConfig = getSourceSyncHistoryConfig(source, Boolean(model?.visual_query_parent_id));

  const [updatingWarehouseSyncLogsConfig, setUpdatingWarehouseSyncLogsConfig] = useState<boolean>(false);

  const { mutateAsync: updateSync } = useUpdateSyncMutation();

  const updateWarehouseSyncLogsConfig = async () => {
    if (!id) {
      return;
    }

    setUpdatingWarehouseSyncLogsConfig(true);
    await updateSync({
      id,
      object: {
        updated_by: userId,
        warehouse_history_config: warehouseSyncLogsConfig,
      },
    });
    setUpdatingWarehouseSyncLogsConfig(false);
    addToast(`Sync configuration updated!`, {
      appearance: "success",
    });
  };

  const dirty = !isEqual(warehouseSyncLogsConfig?.tablesToWrite, config?.tablesToWrite);

  const isOverriding = (table: "snapshot" | "changelog" | "syncRuns") =>
    warehouseSyncLogsConfig?.tablesToWrite?.[table] !== undefined &&
    sourceSyncLogConfig &&
    warehouseSyncLogsConfig?.tablesToWrite?.[table] !== sourceSyncLogConfig.tablesToWrite?.[table];

  return (
    <Row sx={{ alignItems: "flex-start" }}>
      <Grid gap={6} mr={8}>
        <Heading>Warehouse Sync Logs</Heading>
        <Text>
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs`}>Warehouse Sync Logs</Link> writes information
          on the status of each row of your sync back into your warehouse. This is useful for analyzing sync failures and
          changes in your data over time.
          <br />
          <br />
          Specific use cases include{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}>
            analyzing common syncs errors
          </Link>
          ,{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#track-when-users-entered-and-exited-a-model`}>
            visualizing when users enter and exit audiences
          </Link>
          , and{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-current-rows-in-all-models`}>
            tracking the status of rows across syncs
          </Link>
          .
        </Text>
        {!source?.plan_in_warehouse && (
          <Message sx={{ maxWidth: "100%" }} variant="warning">
            This feature can only be enabled if the source is using Warehouse Planning. You can enable Warehouse Planning for
            this source <Link to={`/sources/${source?.id}`}>here</Link>.
          </Message>
        )}
        {source?.plan_in_warehouse && (
          <Field
            description="Choose what you would like to track in the warehouse. The data will be written after each sync run completes."
            label="Tables"
            size="large"
          >
            <Grid gap={2}>
              <Flex sx={{ alignItems: "center" }}>
                <Checkbox
                  description="Tracks the latest status of each row in the sync. This is useful for understanding the overall health of your syncs."
                  label={
                    <Row>
                      Snapshot
                      {isOverriding("snapshot") && (
                        <Text
                          sx={{
                            ml: 1,
                            color: "base.4",
                          }}
                        >
                          (overriding default)
                        </Text>
                      )}
                    </Row>
                  }
                  size="large"
                  value={
                    warehouseSyncLogsConfig?.tablesToWrite?.snapshot ?? sourceSyncLogConfig?.tablesToWrite?.snapshot ?? false
                  }
                  onChange={(value: boolean) => {
                    setWarehouseSyncLogsConfig({
                      tablesToWrite: {
                        ...(warehouseSyncLogsConfig?.tablesToWrite || {}),
                        snapshot: value,
                      },
                    });
                  }}
                />
              </Flex>

              <Flex sx={{ alignItems: "center" }}>
                <Checkbox
                  description="Tracks every operation performed by Hightouch. Includes the result of the operation, as well as any error messages from syncing."
                  label={
                    <Row>
                      Changelog
                      {isOverriding("changelog") && (
                        <Text
                          sx={{
                            ml: 1,
                            color: "base.4",
                          }}
                        >
                          (overriding default)
                        </Text>
                      )}
                    </Row>
                  }
                  size="large"
                  value={
                    warehouseSyncLogsConfig?.tablesToWrite?.changelog ?? sourceSyncLogConfig?.tablesToWrite?.changelog ?? false
                  }
                  onChange={(value: boolean) => {
                    setWarehouseSyncLogsConfig({
                      tablesToWrite: {
                        snapshot: warehouseSyncLogsConfig?.tablesToWrite?.snapshot,
                        changelog: value,
                        syncRuns: warehouseSyncLogsConfig?.tablesToWrite?.syncRuns,
                      },
                    });
                  }}
                />
              </Flex>
              <Flex sx={{ alignItems: "center" }}>
                <Checkbox
                  description={
                    "Contains a log of all the sync runs. The changelog and snapshot tables can be JOINed to this table for more information on when the sync occurred and how it was configured."
                  }
                  label={
                    <Row>
                      Sync Runs
                      {isOverriding("syncRuns") && (
                        <Text
                          sx={{
                            ml: 1,
                            color: "base.4",
                          }}
                        >
                          (overriding default)
                        </Text>
                      )}
                    </Row>
                  }
                  size="large"
                  value={
                    warehouseSyncLogsConfig?.tablesToWrite?.syncRuns ?? sourceSyncLogConfig?.tablesToWrite?.syncRuns ?? false
                  }
                  onChange={(value: boolean) => {
                    setWarehouseSyncLogsConfig({
                      tablesToWrite: {
                        snapshot: warehouseSyncLogsConfig?.tablesToWrite?.snapshot,
                        changelog: warehouseSyncLogsConfig?.tablesToWrite?.changelog,
                        syncRuns: value,
                      },
                    });
                  }}
                />
              </Flex>
            </Grid>
          </Field>
        )}
      </Grid>
      <SidebarForm
        hideCompliance
        hideInviteTeammate
        hideSendMessage
        buttons={
          source?.plan_in_warehouse
            ? [
                <Permission key={0}>
                  <Button
                    disabled={!dirty}
                    loading={updatingWarehouseSyncLogsConfig}
                    sx={{ width: "100%" }}
                    onClick={() => updateWarehouseSyncLogsConfig()}
                  >
                    Save
                  </Button>
                </Permission>,
              ]
            : undefined
        }
        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/`}
        invite="If you need help setting up this sync"
        name="warehouse sync logs"
      />
    </Row>
  );
};

function getSourceSyncHistoryConfig(source: Source | undefined, isVisual: boolean): WarehouseHistoryConfig | undefined {
  if (!source?.warehouse_history_config) {
    return undefined;
  }
  const config: WarehouseHistoryConfig = {
    tablesToWrite: {
      changelog: false,
      syncRuns: false,
      snapshot: false,
    },
  };
  if (source?.warehouse_history_config?.changelog) {
    if (!source?.warehouse_history_config?.changelog?.audience || isVisual) {
      config.tablesToWrite.changelog = true;
    }
  }
  if (source?.warehouse_history_config?.snapshot) {
    if (!source?.warehouse_history_config?.snapshot?.audience || isVisual) {
      config.tablesToWrite.syncRuns = true;
    }
  }
  if (source?.warehouse_history_config?.syncRuns) {
    if (!source?.warehouse_history_config?.syncRuns?.audience || isVisual) {
      config.tablesToWrite.snapshot = true;
    }
  }

  return config;
}
