import { useEffect, useState, FC } from "react";

import { Placement } from "@floating-ui/react-dom";
import { Box, Text } from "theme-ui";

import { Button } from "src/ui/button";
import { XIcon } from "src/ui/icons";
import { Popout } from "src/ui/popout";

import { colors } from "../../../../design/colors";

const StepText: FC<{ step: number }> = ({ step }) => {
  return <Text sx={{ fontSize: "10px", mb: 1 }}>Step {step} of 4</Text>;
};

export const popoverContent = {
  selectSource: {
    variant: "secondary",
    arrowPosition: "left",
    text: <div>A source is where Hightouch pulls data from. These are typically data warehouses and databases.</div>,
  },
  selectDestination: {
    variant: "secondary",
    arrowPosition: "left",
    text: <div>A destination is where Hightouch sends data to. Hightouch supports hundreds of business tools and systems.</div>,
  },
  connectSource: {
    variant: "primary",
    arrowPosition: "left",
    text: (
      <div>
        <StepText step={1} />
        <h5>Connect your source</h5>
        First, connect the source you selected in the previous step.
      </div>
    ),
  },
  setupModel: {
    variant: "primary",
    arrowPosition: "left",
    text: (
      <div>
        <StepText step={2} />
        <h5>Set up a model</h5>
        Next, create a model for how your source data will be queried.
      </div>
    ),
  },
  connectDestination: {
    variant: "primary",
    arrowPosition: "left",
    text: (
      <div>
        <StepText step={3} />
        <h5>Connect your destination</h5>
        Now, connect the destination you selected in the previous step.
      </div>
    ),
  },
  configureSync: {
    variant: "primary",
    arrowPosition: "center",
    text: (
      <div>
        <StepText step={4} />
        <h5>Configure your sync</h5>
        Finally, configure your sync settings! Syncs define how data gets mapped from source to destination.
      </div>
    ),
  },
  runSync: {
    variant: "primary",
    arrowPosition: "center",
    text: <div>Hightouch is ready to send data to your destination! Go to your Sync and click Run.</div>,
  },
  modelDisabled: {
    variant: "secondary",
    arrowPosition: "left",
    text: <div>You must connect your source first before creating a model to query it.</div>,
  },
  syncDisabled: {
    variant: "secondary",
    arrowPosition: "center",
    text: (
      <div>
        A data sync in Hightouch requires a connected data source, a model that selects the data, and a connected destination
        where we send the data.
      </div>
    ),
  },
  createYourFirstSyncDisabled: {
    variant: "secondary",
    arrowPosition: "center",
    text: <div>Select a sync and destination to get started.</div>,
  },
};

type Props = {
  content: keyof typeof popoverContent;
  children?: JSX.Element;
  footer?: JSX.Element;
  isOpen?: boolean;
  asTooltip?: boolean;
  disabled: boolean;
  placement?: Placement;
};

export const Popover: FC<Props> = ({ children, footer, disabled, isOpen, content, asTooltip, placement = "bottom" }) => {
  const text = popoverContent[content].text;
  const variant = popoverContent[content].variant;
  const arrowPosition = popoverContent[content].arrowPosition;

  const [enabled, setEnabled] = useState<boolean>(!disabled);

  // Reset enabled for each onboarding step: isOpen is updated depending on the currentStep of
  // the onboarding process (see the DiagramOnboarding component).
  useEffect(() => {
    setEnabled(Boolean(!disabled));
  }, [isOpen]);

  return (
    <Popout
      portal
      content={
        <Box>
          <Box
            sx={{
              borderStyle: "solid",
              borderColor: variant === "secondary" ? colors.base[8] : colors.primary,
              borderWidth: "0 4px 4px 0",
              display: "inline-block",
              padding: "3px",
              transform: " rotate(-135deg)",
              position: "absolute",
              top: placement === "top" ? "auto" : "-5px",
              bottom: placement === "top" ? "-5px" : "auto",
              bg: variant === "secondary" ? colors.base[8] : colors.primary,
              left: arrowPosition === "center" ? "150px" : "30px",
            }}
          />
          <Box
            sx={{
              fontSize: "13px",
              lineHeight: "24px",
              display: "flex",
              alignContent: "flex-start",
              px: 4,
              py: 5,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                h5: {
                  fontSize: "16px",
                  fontWeight: 600,
                  mt: 0,
                  mb: 3,
                },
              }}
            >
              {!asTooltip && (
                <Button
                  aria-label="Close window"
                  sx={{ position: "absolute", top: 0, right: 2 }}
                  variant="plain"
                  onClick={() => {
                    setEnabled(false);
                  }}
                >
                  <XIcon color="white" size={16} />
                </Button>
              )}
              {text}
              {footer && <Box>{footer}</Box>}
            </Box>
          </Box>
        </Box>
      }
      contentSx={{
        background: variant === "secondary" ? colors.base[8] : colors.primary,
        color: colors.white,
        borderRadius: 6,
        width: "315px",
        shadow: 2,
        display: enabled ? "inline" : "none",
      }}
      ignoreClose={!asTooltip}
      isOpen={isOpen}
      placement={placement}
    >
      {({ open, close }) => (
        <Box
          sx={{ cursor: disabled ? "pointer" : "default", width: "100%" }}
          onMouseEnter={() => asTooltip && open()}
          onMouseLeave={() => asTooltip && close()}
        >
          {children}
        </Box>
      )}
    </Popout>
  );
};
