import { FC, CSSProperties, MouseEvent, ReactElement, ReactNode, useMemo, useCallback } from "react";

import { get } from "lodash";
import { ThemeUIStyleObject } from "theme-ui";

import { OrderBy } from "src/graphql";
import { Box, Row } from "src/ui/box";

import { Indices } from "../../../../design";
import { SortIcon } from "./sortIcon";
import { TableColumn } from "./table";

export type HeaderCell = {
  children: ReactNode;
  onClick?: (event: MouseEvent) => void;
  sortDirection?: OrderBy | null;
};

export type TableCellProps<Data> = {
  column: TableColumn;
  row: Data;
  onClick?: (row: any, event: MouseEvent) => void;
  sx?: CSSProperties;
  height?: number;
};

export const Cell: FC<Readonly<{ children: ReactNode; sx?: CSSProperties; onClick?: (event: MouseEvent) => void }>> = ({
  children,
  sx = {},
  onClick,
}) => {
  const style = useMemo(
    () => ({
      ...cellStyle,
      ...sx,
    }),
    [sx, cellStyle],
  );

  return (
    <Box as="td" sx={style} onClick={onClick}>
      {children}
    </Box>
  );
};

export function TableCell<Data>({
  column: { key, cell, defaultValue = "", divider, disabled },
  row,
  sx,
  height,
}: Readonly<TableCellProps<Data>>): ReactElement {
  const value = key ? get(row, key, defaultValue) : row;

  const style = useMemo(
    () => ({ borderLeft: divider ? "1px solid var(--theme-ui-colors-base-2)" : undefined, height, ...sx }),
    [sx, height, divider],
  );

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (disabled) {
        event.stopPropagation();
      }
    },
    [disabled],
  );

  return (
    <Cell sx={style} onClick={handleClick}>
      <Box sx={cellContentStyle}>{cell ? cell(value) : String(value)}</Box>
    </Cell>
  );
}

export const HeaderCell: FC<HeaderCell> = ({ children, onClick, sortDirection }) => {
  return (
    <Box
      as="th"
      sx={{
        ...headerCellStyle,
        "&:hover > .sort-icon": {
          ...headerCellStyle["&:hover > .sort-icon"],
          backgroundColor: sortDirection ? "#644DED14" : "secondaries.0",
          path: {
            fill: sortDirection ? "primaries.10" : "#808192",
          },
        },
        cursor: onClick ? "pointer" : "auto",
      }}
      onClick={onClick}
    >
      {typeof children === "string" ? (
        <Row sx={{ alignItems: "center" }}>
          <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{children}</Box>
        </Row>
      ) : (
        children
      )}
      {onClick && (
        <SortIcon
          className="sort-icon"
          sortDirection={sortDirection}
          sx={{ opacity: sortDirection ? 1 : 0, visibility: sortDirection ? "visible" : "hidden" }}
        />
      )}
    </Box>
  );
};

const baseCellStyle: CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  textAlign: "left",
  fontSize: "14px",
  width: "100%",
  borderBottom: "1px solid var(--theme-ui-colors-base-2)",
  padding: "0 20px",
  alignItems: "center",
};

const headerCellStyle: ThemeUIStyleObject = {
  ...baseCellStyle,
  flexWrap: "nowrap",
  fontWeight: "bold",
  fontSize: 0,
  height: "40px",
  color: "base.7",
  bg: "base.1",
  position: "sticky",
  textTransform: "uppercase",
  top: 0,
  userSelect: "none",
  letterSpacing: ".5px",
  zIndex: Indices.Content,

  "&:hover > .sort-icon": {
    opacity: 1,
    visibility: "visible",
    transition: "background fill stroke 150ms ease-in",
  },
};

const cellStyle: CSSProperties = {
  ...baseCellStyle,
  backgroundColor: "inherit",
  color: "inherit",
};

const cellContentStyle: ThemeUIStyleObject = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
};
