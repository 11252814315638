import { FC } from "react";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications2";
import { Grid } from "theme-ui";

import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { SequenceForm, FormState } from "src/components/sequences/sequence-form";
import { Slug } from "src/components/slug";
import { useCreateSequenceMutation, SyncSequenceMembersInsertInput, useIsResourceSlugAvailableQuery } from "src/graphql";
import { Box, Container } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useNavigate } from "src/utils/navigate";
import { generateSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

export const CreateSequence: FC = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);

  const { mutateAsync: createSequence, isLoading: creating } = useCreateSequenceMutation();

  const formMethods = useForm<FormState>({
    defaultValues: {
      slug: generateSlug("sequence"),
      name: "",
      members: [],
      schedule: { schedule: null, type: ScheduleType.MANUAL },
    },
  });
  const { handleSubmit, control, watch, register } = formMethods;
  const name = watch("name");
  const schedule = watch("schedule");
  const members = watch("members");
  const slug = watch("slug");

  const { data: isSlugAvailable, isLoading: loadingSlug } = useIsResourceSlugAvailableQuery(
    {
      resourceType: "sync_sequences",
      slug,
    },
    { enabled: Boolean(slug), select: (data) => data.isResourceSlugAvailable },
  );

  const submit = async (data) => {
    const members = data.members.map(({ sync, abort_seq_on_failure, abort_seq_on_rejects }, index) => {
      const member: SyncSequenceMembersInsertInput = {
        sync_id: sync.id,
        reference_id: index + 1,
        abort_seq_on_failure,
        abort_seq_on_rejects,
        links: {
          predecessors: index > 0 ? [index] : [],
          successors: index !== data.members.length - 1 ? [index + 2] : [],
        },
      };

      return member;
    });

    const sequence = await createSequence({
      object: {
        slug: data.slug,
        name: data.name,
        schedule: data.schedule?.type === "manual" ? null : data.schedule,
        members: { data: members },
      },
    });

    if (sequence.insert_sync_sequences_one) {
      addToast(`Sequence created!`, {
        appearance: "success",
      });

      navigate(`/sequences/${sequence.insert_sync_sequences_one.id}`);
    }
  };

  const steps: Step[] = [
    {
      title: "Configure",
      disabled: !(members.length > 1),
      render: () => {
        return (
          <Box>
            <Heading sx={{ mb: 6 }}>Add syncs to your sequence</Heading>
            <FormProvider {...formMethods}>
              <SequenceForm />
            </FormProvider>
          </Box>
        );
      },
    },
    {
      title: "Finish",
      disabled: !name || !schedule || !slug || !isSlugAvailable,
      loading: creating,
      render: () => {
        return (
          <Container size="small">
            <Grid gap={8}>
              <Heading>Finalize your settings</Heading>
              <Field label="Sequence name">
                <Input autoFocus {...register("name")} />
              </Field>
              <Field label="Slug">
                <Controller
                  control={control}
                  name="slug"
                  render={({ field }) => (
                    <Slug
                      available={Boolean(isSlugAvailable)}
                      loading={loadingSlug}
                      placeholder={"your-sequence-slug"}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Field>
              <Controller
                control={control}
                name="schedule"
                render={({ field }) => (
                  <ScheduleManager resource={"sequence"} schedule={field.value} setSchedule={field.onChange} />
                )}
              />
            </Grid>
          </Container>
        );
      },
    },
  ];

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="Add a sequence"
      onCancel={() => {
        navigate("/sequences");
      }}
      onSubmit={handleSubmit(submit)}
    />
  );
};
