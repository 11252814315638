import { FC } from "react";

import { DestinationDefinition, DestinationQuery, ModelQuery } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { WizardOnboarding } from "src/ui/wizard";

import { useCreateSyncWizard } from "../syncs/create/use-create-sync-wizard";

type Props = {
  destination?: DestinationQuery["destinations_by_pk"];
  destinationDefinition?: DestinationDefinition;
  model?: ModelQuery["segments_by_pk"];
  onSubmit?: ({ id }: { id: string }) => void;
  onCancel: () => void;
};

export const OnboardingCreateSync: FC<Readonly<Props>> = ({
  destination,
  destinationDefinition,
  model,
  onSubmit,
  onCancel,
}) => {
  const { createSync, loading, setStep, step, steps } = useCreateSyncWizard({
    initialStep: 2,
    model,
    destination,
    destinationDefinition,
    onSubmit,
  });

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <WizardOnboarding
      setStep={setStep}
      step={step}
      steps={steps}
      title="Add a new sync"
      onCancel={onCancel}
      onSubmit={createSync}
    />
  );
};
