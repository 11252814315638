import { FC, useState } from "react";

import { useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { useAuth0ConnectionsByOrganizationQuery, usePartnerConnectGetInformationQuery } from "src/graphql";
import { Fade } from "src/ui/animations";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { useQueryString } from "src/utils/use-query-string";

import { Header, Terms } from "./continue";

interface LocationState {
  from?: {
    pathname: string;
  };
}

export const SSO: FC = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const {
    data: { returnTo: queryLocation },
  } = useQueryString();
  const client = useQueryClient();
  const returnTo = queryLocation || locationState?.["from"]?.["pathname"];
  const match = returnTo?.match(/^\/?partner-connect\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})$/i);

  const { org } = useParams<{ org: string }>();
  const [organization, setOrganization] = useState<string | undefined>(org);
  const [_error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { data: partnerInformation } = usePartnerConnectGetInformationQuery(
    { uuid: match?.[1] ?? "" },
    { enabled: Boolean(match?.length), select: (data) => data.partnerConnectGetInformation, suspense: true },
  );

  const getAuth0AuthUrl = (organizationSlug: string, connectionName: string) => {
    const auth0AuthUrl = new URL(`${import.meta.env.VITE_API_BASE_URL}/auth0/oauth/${organizationSlug}/${connectionName}`);

    if (returnTo) {
      auth0AuthUrl.searchParams.append("returnTo", returnTo);
    }
    return auth0AuthUrl;
  };

  const getAuth0Connections = async () => {
    if (!organization) {
      return;
    }

    setLoading(true);
    const variables = {
      slug: organization,
    };
    try {
      const { getAuth0ConnectionsByOrganization } = await client.fetchQuery(
        useAuth0ConnectionsByOrganizationQuery.getKey(variables),
        useAuth0ConnectionsByOrganizationQuery.fetcher(variables),
      );

      const connection = getAuth0ConnectionsByOrganization.auth0Connections[0];

      if (!connection) {
        setError(true);
        return;
      }

      const url = getAuth0AuthUrl(organization, connection.name).toString();
      window.open(url);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ maxWidth: "550px", width: "100%" }}>
      <Fade sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
        <Header partnerInformation={partnerInformation} />
        <form
          onSubmit={(event) => {
            event.preventDefault();
            getAuth0Connections();
          }}
        >
          <Input
            autoFocus
            placeholder="Enter your organization slug..."
            size="large"
            sx={{ mb: 4, width: "250px" }}
            value={organization || ""}
            onChange={(org) => {
              setOrganization(org);
            }}
          />

          <Button
            disabled={!organization}
            label="Continue with SSO"
            loading={loading}
            size="large"
            sx={{ width: "250px", ":not(:disabled):hover": { bg: "#4125C1" } }}
            type="submit"
            variant="primary"
          />
        </form>

        <Link
          slug={false}
          sx={{
            mt: 10,
            fontSize: 2,
            fontWeight: "bold",
            color: "base.4",
            ":hover": { color: "base.7" },
            transition: "150ms color",
          }}
          to={returnTo ? `/login?returnTo=${returnTo}` : "/login"}
        >
          {`<- Other options`}
        </Link>
        <Terms />
      </Fade>
    </Card>
  );
};
