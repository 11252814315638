import { FC, useState } from "react";

import { useToasts } from "react-toast-notifications2";
import { Text, Grid, Flex } from "theme-ui";
import { useClipboard } from "use-clipboard-copy";

import { Permission } from "src/components/permission";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useConfigureSsoMutation,
  useDeleteSsoGroupRolesMutation,
  useInsertSsoGroupRolesMutation,
  useWorkspaceQuery,
  useWorkspacesOrganizationsGroupsQuery,
  WorkspacesOrganizationsGroupsQuery,
} from "src/graphql";
import { Badge } from "src/ui/badge";
import { Column, Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { FileUploader } from "src/ui/file";
import { CheckIcon, CopyIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";

export const Organization: FC = () => {
  return (
    <Settings route="sso">
      <Container center={false} size="medium" sx={{ mx: "auto" }}>
        <Grid gap={12}>
          <PermissionProvider permissions={[]}>
            <General />
          </PermissionProvider>
        </Grid>
      </Container>
    </Settings>
  );
};

const General: FC = () => {
  const { workspace: _workspace } = useUser();
  const { data: workspaceData } = useWorkspaceQuery({ workspaceId: _workspace?.id }, { enabled: Boolean(_workspace) });
  const [ssoModalOpen, setSsoModalOpen] = useState(false);

  const organization = workspaceData?.workspaces_by_pk?.organization;

  const isSsoEnabled = (organization?.auth0_connections || []).length > 0;

  const clipboardLoginUrl = useClipboard({
    copiedTimeout: 600,
  });

  const loginUrl = "https://app.hightouch.com/sso/" + organization?.slug;

  return (
    <Grid gap={8}>
      <Field
        description="Single Sign On (SSO) is a security feature available for Business Tier workspaces. Contact us to enable this for your workspace."
        label="Single Sign On"
      >
        <Grid gap={4}>
          {/* Can't be toggled in App. */}
          <Badge variant={isSsoEnabled ? "green" : "yellow"}>{isSsoEnabled ? "Enabled" : "Disabled"}</Badge>
          {organization?.plan?.sku === "business_tier" && (
            <Button sx={{ mt: 2 }} onClick={() => setSsoModalOpen(true)}>
              {isSsoEnabled ? "Update SAML SSO" : "Add SAML SSO"}
            </Button>
          )}
          <AddSsoModal
            close={() => {
              setSsoModalOpen(false);
            }}
            open={ssoModalOpen}
            organizationSlug={organization?.slug || ""}
          />
          {isSsoEnabled && (
            <Row sx={{ alignItems: "center", position: "relative", maxWidth: 400 }}>
              <Input readOnly sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }} value={loginUrl} />
              <Button
                sx={{
                  position: "absolute",
                  right: 0,
                  width: "40px",
                  bg: "base.2",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                variant="plain"
                onClick={() => clipboardLoginUrl.copy(loginUrl)}
              >
                {clipboardLoginUrl.copied ? <CheckIcon color="green" size={18} /> : <CopyIcon size={16} />}
              </Button>
            </Row>
          )}
        </Grid>

        <Permission
          permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update], resource_id: _workspace?.id }]}
        >
          <SsoGroupMapping />
        </Permission>
      </Field>
    </Grid>
  );
};

interface AddSsoModalProps {
  open: boolean;
  close: () => void;
  organizationSlug: string;
}

const AddSsoModal: FC<AddSsoModalProps> = ({ open, close, organizationSlug }) => {
  const [cert, setCert] = useState("");
  const [signInEndpoint, setSignInEndpoint] = useState("");
  const { addToast } = useToasts();
  const clipboardSamlUrl = useClipboard({
    copiedTimeout: 600,
  });
  const clipboardAudienceUrl = useClipboard({
    copiedTimeout: 600,
  });

  const { mutateAsync: addSso, isLoading } = useConfigureSsoMutation();

  const handleClose = () => {
    close();
  };

  const save = async () => {
    try {
      await addSso({
        details: {
          cert,
          signInEndpoint,
        },
      });
      addToast("SSO configuration updated", { appearance: "success" });
      close();
    } catch (err) {
      addToast("Failed to configure SSO", { appearance: "error" });
    }
  };

  const audienceValue = `urn:auth0:hightouch:${organizationSlug}-1`;
  const samlUrl = `https://hightouch.us.auth0.com/login/callback?connection=${organizationSlug}-1`;

  return (
    <Modal
      bodySx={{ pb: 6 }}
      footer={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={isLoading || !cert || !signInEndpoint} loading={isLoading} onClick={save}>
            Save
          </Button>
        </>
      }
      isOpen={open}
      sx={{ maxWidth: "900px", width: "100%" }}
      title="Add SSO Connection"
      onClose={handleClose}
    >
      <Field
        description={
          <Text>
            If you need help, view our docs <Link to="https://hightouch.com/docs/workspace-management/sso">here</Link>
          </Text>
        }
        label="Step 1: Set up your SSO application in your identity provider"
        size="large"
      >
        <Grid gap={4}>
          <Field description="Use the following SAML URL configuration" label="SAML URL">
            <Row sx={{ alignItems: "center", position: "relative" }}>
              <Input readOnly sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }} value={samlUrl} />
              <Button
                sx={{
                  position: "absolute",
                  right: 0,
                  width: "40px",
                  bg: "base.2",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                variant="plain"
                onClick={() => clipboardSamlUrl.copy(samlUrl)}
              >
                {clipboardSamlUrl.copied ? <CheckIcon color="green" size={18} /> : <CopyIcon size={16} />}
              </Button>
            </Row>
          </Field>
          <Field description="Use the following SAML Audience URI" label="SAML Audience URL">
            <Row sx={{ alignItems: "center", position: "relative" }}>
              <Input readOnly sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }} value={audienceValue} />
              <Button
                sx={{
                  position: "absolute",
                  right: 0,
                  width: "40px",
                  bg: "base.2",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                variant="plain"
                onClick={() => clipboardAudienceUrl.copy(audienceValue)}
              >
                {clipboardAudienceUrl.copied ? <CheckIcon color="green" size={18} /> : <CopyIcon size={16} />}
              </Button>
            </Row>
          </Field>
          <Field label="Step 2: Provide the details of your SSO application" size="large" sx={{ mt: 8 }}>
            <Field
              description="This is the URL your identity provider (Okta, Azure AD, etc.) provides when completing the configuration of a SAML application."
              label="Identity Provider Single Sign-On URL"
            >
              <Input
                placeholder="Enter your SAML Sign In Endpoint"
                value={signInEndpoint}
                onChange={(endpoint) => {
                  setSignInEndpoint(endpoint);
                }}
              />
            </Field>
          </Field>
          <Field
            description="This is a text file that usually starts with BEGIN CERTIFICATE. Please upload the entire file as provided by your identity provider."
            label="x.509 Certificate"
          >
            <FileUploader
              acceptedFileTypes={[".pem", ".crt", ".cert"]}
              transformation={"string"}
              value={cert}
              onChange={(value) => {
                setCert(value);
              }}
            />
          </Field>
        </Grid>
      </Field>
    </Modal>
  );
};

const SsoGroupMapping: FC = () => {
  const { data, isLoading, refetch } = useWorkspacesOrganizationsGroupsQuery();

  if (isLoading) {
    return <Spinner />;
  }

  if ((data?.organizations[0]?.workspaces || []).length === 0) {
    return null;
  }

  if (data?.organizations[0]?.sso_groups.length === 0) {
    return null;
  }

  return (
    <Flex padding={2} sx={{ mt: 8, flexDirection: "column" }}>
      <Section title="Workspaces">
        <ul>
          {data?.organizations[0]?.workspaces.map((workspace) => {
            return (
              <Row
                key={workspace?.id}
                as={"li"}
                gap={8}
                sx={{ borderColor: "base.3", borderBottomStyle: "solid", py: 4, flex: 1, display: "flex" }}
              >
                <Column>
                  <Text sx={{ width: "100px" }}>{workspace.name}</Text>
                </Column>
                <Grid columns={[2, "1fr 6fr"]} gap={2} sx={{ alignItems: "center", width: "100%" }}>
                  {data?.organizations[0]?.sso_groups.map((ssoGroup) => {
                    return <WorkspaceGroupMapping key={ssoGroup.id} group={ssoGroup} refetch={refetch} workspace={workspace} />;
                  })}
                </Grid>
              </Row>
            );
          })}
        </ul>
      </Section>
    </Flex>
  );
};

interface WorkspaceGroupMappingProps {
  group: WorkspacesOrganizationsGroupsQuery["organizations"][0]["sso_groups"][0];
  refetch: () => void;
  workspace: WorkspacesOrganizationsGroupsQuery["organizations"][0]["workspaces"][0];
}

const WorkspaceGroupMapping: FC<WorkspaceGroupMappingProps> = ({ group, refetch, workspace }) => {
  const { mutateAsync: insertSsoGroupRoles } = useInsertSsoGroupRolesMutation();
  const { mutateAsync: deleteSsoGroupRoles } = useDeleteSsoGroupRolesMutation();

  const roleOptions = workspace.roles.map((role) => {
    return {
      value: role.id,
      label: role.name,
    };
  });

  const existingRoleMapping = workspace.sso_group_roles.find((ssoGroupRole) => ssoGroupRole.sso_group.id === group.id);

  return (
    <>
      <Column sx={{ textAlign: "right" }}>
        <Text sx={{ mr: 4 }}>{group.name}</Text>
      </Column>
      <Column>
        <Select
          options={roleOptions}
          placeholder="Select role"
          value={existingRoleMapping ? { value: existingRoleMapping.role.id, label: existingRoleMapping.role.name } : null}
          onChange={async (value) => {
            const queries: Promise<any>[] = [];

            // if this group is already mapped to a role, we delete the existing mapping.
            if (existingRoleMapping) {
              queries.push(
                deleteSsoGroupRoles({
                  where: {
                    group_id: {
                      _eq: group.id,
                    },
                    role_id: {
                      _eq: existingRoleMapping.role.id,
                    },
                    workspace_id: {
                      _eq: workspace.id,
                    },
                  },
                }),
              );
            }

            // map this group to this role.
            queries.push(
              insertSsoGroupRoles({
                objects: {
                  role_id: value.value,
                  group_id: group.id,
                  workspace_id: workspace.id,
                },
              }),
            );

            await Promise.all(queries);
            refetch();
          }}
        />
      </Column>
    </>
  );
};
