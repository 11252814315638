import { FC, useEffect } from "react";

import { isNil, omitBy } from "lodash";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Text, Image } from "theme-ui";

import placeholder from "src/components/workspaces/placeholder.svg";
import { useUser } from "src/contexts/user-context";
import {
  useAvailableWorkspacesQuery,
  useJoinWorkspaceWithAutoJoinMutation,
  useMembershipRequestsByUserQuery,
  usePartnerConnectLinkWorkspaceMutation,
  useRequestMembershipMutation,
  useWorkspacesQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations";
import { Container, Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { PlusIcon, ChevronRightIcon } from "src/ui/icons";
import { useNavigate } from "src/utils/navigate";
import { switchWorkspace } from "src/utils/workspaces";

import { PartnerConnectLogo } from "../partner-connect";

export const Workspaces: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const partnerConnection = (state as any)?.partnerConnection;

  const createWorkspace = () => {
    navigate("/workspaces/new", { slug: false, state: { partnerConnection } });
  };

  const { user } = useUser();
  const { email, name, id } = user ?? {};

  const { data: workspaceData, isLoading: workspaceLoading } = useWorkspacesQuery(undefined, { suspense: true });
  const { data: availableData } = useAvailableWorkspacesQuery(undefined, { suspense: true });
  const { mutateAsync: linkWorkspace } = usePartnerConnectLinkWorkspaceMutation();

  const workspaces = workspaceData?.workspaces || [];
  const joinableWorkspaces = availableData?.getAvailableWorkspaces?.joinable || [];
  const visibleWorkspaces = availableData?.getAvailableWorkspaces?.visible || [];

  const workspaceEmpty = !workspaces?.length && !joinableWorkspaces?.length && !visibleWorkspaces?.length;

  useEffect(() => {
    setTimeout(() => {
      if (!workspaceLoading) {
        analytics.identify(String(id), { email, name });
        const wsNames =
          workspaces?.map(function (obj) {
            return omitBy({ name: obj?.name, slug: obj?.slug, workspace_id: obj?.id }, isNil);
          }) || [];
        analytics.track("Manage Workspace Page Visited", { workspaces: wsNames });
      }
    }, 500);
  }, [workspaceLoading]);

  return workspaceEmpty ? (
    <Placeholder onCreate={createWorkspace} />
  ) : (
    <Content
      joinableWorkspaces={joinableWorkspaces}
      partnerConnection={partnerConnection}
      visibleWorkspaces={visibleWorkspaces}
      workspaces={workspaces}
      onCreate={createWorkspace}
      onSelect={async (id, slug) => {
        if (partnerConnection) {
          await linkWorkspace({ uuid: partnerConnection.uuid, workspaceId: Number(id) });
          navigate(`/partner-connect/${partnerConnection.uuid}`, { slug: false });
        } else {
          switchWorkspace(id, `/${slug}`);
        }
      }}
    />
  );
};

export const Content: FC<{
  partnerConnection: any;
  onCreate: () => void;
  onSelect: (id: string, slug: string) => Promise<void>;
  workspaces: any;
  visibleWorkspaces: any;
  joinableWorkspaces: any;
}> = ({ onCreate, onSelect, workspaces, visibleWorkspaces = [], joinableWorkspaces = [], partnerConnection }) => {
  const { user } = useUser();
  const { addToast } = useToasts();

  const { data: membershipData, isLoading: membershipLoading } = useMembershipRequestsByUserQuery(
    {
      userId: String(user?.id),
    },
    { enabled: Boolean(user) },
  );
  const { isLoading: requestLoading, mutateAsync: requestMembership } = useRequestMembershipMutation();
  const { mutateAsync: joinWithAutojoin } = useJoinWorkspaceWithAutoJoinMutation();

  const membershipRequests = membershipData?.membership_requests;

  return (
    <Container size="650px">
      <Fade>
        <Column sx={{ p: 10 }}>
          {partnerConnection && (
            <PartnerConnectLogo logo={partnerConnection.partnerLogo} name={partnerConnection.partnerName} />
          )}

          <Text sx={{ fontSize: 7, fontWeight: "bold", mb: 10, textAlign: "center" }}>
            Select a workspace{partnerConnection ? " to connect" : ""}
          </Text>
        </Column>

        {workspaces?.length > 0 && (
          <Column sx={{ border: "small", borderRadius: 4, overflow: "hidden", bg: "white" }}>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", px: 4, height: "60px" }}>
              <Text
                sx={{
                  color: "base.5",
                  fontSize: 2,
                }}
              >
                Your workspaces
              </Text>
              <Button variant="white" onClick={onCreate}>
                <PlusIcon size={16} />
                <Text sx={{ ml: 2 }}>Add workspace</Text>
              </Button>
            </Row>

            {workspaces?.map(({ slug, name, id, memberships, organization }) => (
              <Workspace
                key={id}
                id={id}
                name={name}
                organization={organization?.name}
                size={memberships.length}
                onClick={(id) => {
                  onSelect(id, slug);
                }}
              />
            ))}
          </Column>
        )}

        {!!(visibleWorkspaces?.length || joinableWorkspaces?.length) && (
          <Column sx={{ mt: 10, border: "small", borderRadius: 2, overflow: "hidden", bg: "white" }}>
            <Row sx={{ px: 4, height: "60px", alignItems: "center", justifyContent: "space-between" }}>
              <Text
                sx={{
                  color: "base.5",
                  fontSize: 2,
                }}
              >
                Available workspaces
              </Text>
              {!workspaces?.length && (
                <Button variant="white" onClick={onCreate}>
                  <PlusIcon size={16} />
                  <Text sx={{ ml: 2 }}>Add workspace</Text>
                </Button>
              )}
            </Row>

            {visibleWorkspaces?.map(({ name, id }) => (
              <Workspace
                key={id}
                btnDisabled={membershipRequests?.some((r) => r?.workspace_id === id)}
                btnLoading={requestLoading || membershipLoading}
                id={id}
                name={name}
                type="request"
                onClick={async (id) => {
                  await requestMembership({ workspaceId: String(id) });
                  addToast(
                    `Access to workspace ${name} sent! Ask a member of the workspace to approve your request in the manage members page.`,
                    {
                      appearance: "success",
                    },
                  );
                }}
              />
            ))}
            {joinableWorkspaces?.map(({ name, id, slug }) => (
              <Workspace
                key={id}
                btnDisabled={membershipRequests?.some((r) => r?.workspace_id === id)}
                btnLoading={requestLoading || membershipLoading}
                id={id}
                name={name}
                type="join"
                onClick={async (id) => {
                  await joinWithAutojoin({ workspaceId: String(id) });
                  onSelect(id, slug);
                }}
              />
            ))}
          </Column>
        )}
      </Fade>
    </Container>
  );
};

const Workspace: FC<{
  name: string;
  organization?: string;
  onClick: (id: string) => void;
  id: string;
  btnLoading?: boolean;
  btnDisabled?: boolean;
  size?: number;
  type?: "default" | "request" | "join";
}> = ({ name, size, id, organization, onClick, type = "default", btnLoading = false, btnDisabled = false }) => (
  <Row
    sx={{
      px: 4,
      height: "72px",
      width: "100%",
      alignItems: "center",
      borderTop: "small",
      justifyContent: "space-between",
      ...(type === "default"
        ? {
            cursor: "pointer",
            transition: "150ms all",
            ":hover": {
              bg: "secondaries.0",
            },
          }
        : {
            bg: "base.1",
            color: "base.5",
          }),
    }}
    onClick={
      type === "default"
        ? async () => {
            await onClick(id);
          }
        : undefined
    }
  >
    <Text
      sx={{
        fontSize: 2,
        fontWeight: "bold",
        mr: 4,
        width: "30%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {name}
    </Text>

    {Boolean(organization) && (
      // Waiting for better design
      <Text sx={{ mr: 4, display: "none" }} variant="subtle">
        {organization}
      </Text>
    )}
    {Boolean(size) && (
      <Text sx={{ mr: 4 }} variant="subtle">
        {size} member{typeof size === "number" && size > 1 ? "s" : ""}
      </Text>
    )}
    {type === "default" && <ChevronRightIcon color="base.4" />}
    {type === "request" && (
      <Button
        disabled={btnDisabled}
        label={btnDisabled ? "Awaiting Approval" : "Request Access"}
        loading={btnLoading}
        variant="white"
        onClick={async () => {
          await onClick(id);
        }}
      />
    )}
    {type === "join" && (
      <Button
        disabled={btnDisabled}
        label={"Join Workspace"}
        loading={btnLoading}
        variant="white"
        onClick={async () => {
          await onClick(id);
        }}
      />
    )}
  </Row>
);

export const Placeholder: FC<{ onCreate: () => void }> = ({ onCreate }) => (
  <Column
    sx={{
      bg: "white",
      alignItems: "center",
      mx: "auto",
      borderRadius: 3,
      border: "small",
      mt: 4,
      px: 7,
      py: 14,
      maxWidth: "618px",
      width: "100%",
      textAlign: "center",
    }}
  >
    <Image src={placeholder} />
    <Text sx={{ fontSize: 3, fontWeight: "bold", mt: 8, mb: 4 }}>You have no workspaces yet</Text>
    <Text sx={{ color: "dark.2", mb: 4, fontWeight: 400 }}>
      Get started by creating your first workspace. Invite people to different workspaces to keep your team’s workflows secure
      and organized.
    </Text>
    <Button onClick={onCreate}>Create a workspace</Button>
  </Column>
);
