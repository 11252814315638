import { FC } from "react";

import { Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { useResourceLabelsQuery } from "src/graphql";
import { LabelIcon } from "src/ui/icons";
import { MultiSelect } from "src/ui/new-select";

type Props = {
  resourceType: "sync" | "destination" | "segment" | "source";
  value: string[];
  onChange: (value: string[]) => void;
};

export const LabelSelect: FC<Readonly<Props>> = ({ resourceType, value, onChange }) => {
  const { data: labelsData } = useResourceLabelsQuery({}, { enabled: Boolean(resourceType) });

  const labelOptions = labelsData?.resource_tag_values.reduce(
    (options, { key, values }) => [
      ...options,
      ...values.map((value) => ({
        label: `${key}:${value}`,
        value: `${key}:${value}`,
        render: () => (
          <>
            <Text sx={{ fontWeight: "thin", mr: 1 }}>{key}:</Text>
            <Text>{value}</Text>
          </>
        ),
      })),
    ],
    [] as { label: string; value: string }[],
  );

  const keyOptions = labelsData?.resource_tag_values?.map(({ key }) => ({
    label: key ?? "",
    value: key,
    render: () => (
      <>
        <LabelIcon size={12} sx={{ mr: 1 }} />
        {key}
      </>
    ),
  }));

  const options = [
    {
      label: "Pairs",
      options: labelOptions,
    },
    {
      label: "Keys",
      options: keyOptions,
    },
  ];

  return (
    <MultiSelect
      label="label"
      options={options}
      placeholder="Labels..."
      value={value}
      onChange={(value) => {
        onChange(value.filter(isPresent));
      }}
    />
  );
};
