import { Fragment, useEffect, FC } from "react";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Flex, Grid, Text } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon, TraitIcon, XIcon } from "src/ui/icons";
import { LightningIcon } from "src/ui/icons/lightning";
import { Input } from "src/ui/input";
import { CreatableSelect, Select } from "src/ui/select";
import { flattenOptions } from "src/ui/select/select";
import { Tooltip } from "src/ui/tooltip";
import { automap, suggest } from "src/utils/automap";
import { ICON_MAP } from "src/utils/destinations";

import { Option } from "../../../../formkit";
import { AssociationMapper, AssociationOptions } from "./association-mapper";
import { useFormkitContext } from "./formkit-context";
import { Mapper, resolveFromValue } from "./mapper";
import { MappingsHeader } from "./mappings-header";
import { MappingType } from "./types";

type Props = {
  name: string;
  options?: any;
  loading?: any;
  object?: string;
  error?: string;
  required?: string;
  creatable?: boolean;
  /** `creatableTypes` are the types the user can select from when creating their own field. */
  creatableTypes?: Option[];
  autoSyncColumnsDefault?: boolean;
  excludeMappings?: string[];
  reload?: () => void;
  advanced?: boolean;
  associationOptions?: AssociationOptions;
  templates?: { name: string; description: string; placeholders?: string[] }[];

  allEnabled?: boolean;
  allEnabledKey?: string;
  allEnabledLabel?: string;
};

export const formatOptionLabel = (object) => {
  const Icon = ICON_MAP[object.type];

  return (
    <Row sx={{ alignItems: "center" }}>
      {Icon && object.type && (
        <Row sx={{ mr: 2 }}>
          <Icon size={14} />
        </Row>
      )}
      <Text>{object.label}</Text>
      {object?.object?.label && <Text sx={{ fontSize: 1, color: "blacks.5", ml: 2 }}>{object?.object?.label}</Text>}
    </Row>
  );
};

const retrieveErrorMessage = (errors, mappingCount: number, name: string) => {
  if (errors) {
    for (let i = 0; i < mappingCount; i++) {
      for (const direction of ["from", "to"]) {
        const mappingPath = `${name}[${i}].${direction}`;
        const errorMessage = errors?.[mappingPath];
        if (typeof errorMessage === "string") {
          return errorMessage.replace(mappingPath, "This");
        }
      }
    }
  }
  return "";
};

export const Mappings: FC<Readonly<Props>> = ({
  name,
  object,
  options,
  required,
  loading,
  reload,
  creatable,
  creatableTypes,
  allEnabled,
  allEnabledLabel,
  allEnabledKey,
  autoSyncColumnsDefault = false,
  excludeMappings = [],
  advanced,
  associationOptions,
  templates,
}) => {
  const { columns } = useFormkitContext();

  const { errors } = useDestinationForm();
  const { watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });
  const autoSyncKey = allEnabledKey ?? "autoSyncColumns";
  const watchAutoSyncColumns = watch(autoSyncKey);

  useEffect(() => {
    if (watchAutoSyncColumns) {
      setValue(name, []);
    }
  }, [watchAutoSyncColumns]);

  const watchFieldArray = watch(name);

  useEffect(() => {
    if (!watchFieldArray) {
      setValue(name, []);
    }
  }, [watchFieldArray]);

  const controlledFields =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    }) || [];

  useEffect(() => {
    if (allEnabled && watchAutoSyncColumns === undefined && !controlledFields.length) {
      setValue(autoSyncKey, autoSyncColumnsDefault);
    } else if (!allEnabled) {
      setValue(autoSyncKey, undefined);
    }
  }, [autoSyncColumnsDefault]);

  useEffect(() => {
    const subscription = watch((state, { name: key }) => {
      const currentMappings = state[name];

      // handles when externalIdMapping overlaps with current component
      if (currentMappings && key && excludeMappings.includes(key)) {
        const value = state[key];
        let otherMappings: any[] = [];
        if (Array.isArray(value)) {
          otherMappings = value;
        } else if (typeof value === "object") {
          otherMappings = [value];
        }

        const fieldsWithoutExcluded = currentMappings.filter((c) => !otherMappings.some((e) => c.to === e.to));
        setValue(name, fieldsWithoutExcluded);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const excludedFields: any[] = [];

  for (const key of excludeMappings) {
    const watchMapping = watch(key);
    if (Array.isArray(watchMapping)) {
      excludedFields.push(...watchMapping);
    } else if (typeof watchMapping === "object") {
      excludedFields.push(watchMapping);
    }
  }

  // handles existing mappings
  const isOptionExcluded = (option) => {
    const valueAlreadyMapped = controlledFields.some(({ to }) => to === option.value);
    const usedInOtherMappings = excludedFields.some(({ to }) => to === option.value);

    return valueAlreadyMapped || usedInOtherMappings;
  };

  // handles required mappings
  useEffect(() => {
    const requiredOptionsNotPresent = options?.filter((option) => option.required && !isOptionExcluded(option));
    if (requiredOptionsNotPresent?.length) {
      const requiredMappings = requiredOptionsNotPresent.map((r) => {
        // Map references differently
        if (r.type === "REFERENCE") {
          return {
            to: r.value,
            type: "reference",
            lookup: {
              object: r.objectType || r.value,
            },
            object: r.object?.value,
          };
        }

        return {
          to: r.value,
          type: "standard",
          object: r.object?.value,
        };
      });
      append(requiredMappings);
    }
  }, [controlledFields, excludedFields, options]);

  if (!watchFieldArray) {
    return null;
  }

  if (allEnabled && watchAutoSyncColumns) {
    return (
      <Controller
        name={autoSyncKey}
        render={({ field }) => (
          <Checkbox
            label={allEnabledLabel || "Sync all columns (columns will be synced with the same name)"}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    );
  }

  return (
    <Column aria-label={`${name} mappings`}>
      {allEnabled && (
        <Controller
          name={autoSyncKey}
          render={({ field }) => (
            <Checkbox
              label={allEnabledLabel || "Sync all columns (columns will be synced with the same name)"}
              sx={{ mb: 4 }}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      )}

      <Grid sx={{ gridTemplateColumns: "1fr max-content 1fr max-content", alignItems: "center" }}>
        {controlledFields?.length > 0 && <MappingsHeader columns={4} loading={loading} object={object} reload={reload} />}

        {controlledFields.map(({ id }, index) => (
          <Fragment key={id}>
            <Controller
              name={`${name}.${index}`}
              render={(data) => {
                const destinationFieldOptions = options?.map((option) => {
                  if (isOptionExcluded(option)) {
                    return { ...option, disabled: true };
                  } else {
                    return option;
                  }
                });

                const selectedOption = options?.find((option) => option.value == data.field.value?.to);

                const isMappingRequired = Boolean(selectedOption?.required);
                const referenceObjectOptions = selectedOption?.referenceObjects;

                const selectFieldProps = {
                  readOnly: isMappingRequired,
                  formatOptionLabel,
                  options: destinationFieldOptions,
                  value: data.field.value?.to,
                  onChange: (option) => {
                    if (option?.type === "REFERENCE") {
                      data.field.onChange({
                        to: option?.value,
                        object: option?.object?.value,
                        lookup: {
                          object: option?.referenceObjects?.[0]?.value || option?.objectType || option?.value,
                          from: data.field.value?.from,
                        },
                        type: "reference",
                      });
                    } else {
                      if (data.field.value?.type === "reference") {
                        data.field.onChange({
                          from: data.field.value?.lookup?.from,
                          to: option?.value,
                          object: option?.object?.value,
                          type: "standard",
                        });
                      } else {
                        data.field.onChange({
                          ...data.field.value,
                          to: option?.value,
                          object: option?.object?.value,
                          type: data.field.value?.type,
                        });
                      }
                    }
                  },
                };

                return (
                  <>
                    {data.field.value?.type === "reference" ? (
                      <AssociationMapper
                        associationOptions={associationOptions}
                        index={index}
                        name={name}
                        value={data.field.value}
                        onChange={data.field.onChange}
                      />
                    ) : advanced ? (
                      <Mapper
                        isError={!!errors?.[`${name}[${index}].from`]}
                        placeholder="Select a value..."
                        templates={templates ?? []}
                        value={data.field.value}
                        onChange={(value) => {
                          const availableOptions = destinationFieldOptions?.filter((o) => !o.disabled);
                          if (!data.field.value?.to && availableOptions?.length && value.type === MappingType.STANDARD) {
                            // Ensure that the fieldName is used for the label property since FuzzySet methods (used in automap.ts)
                            // require string arguments.
                            const fieldName = resolveFromValue(value.from);
                            data.field.onChange(suggest({ label: fieldName, value: value.from }, availableOptions));
                          } else {
                            data.field.onChange({ to: data.field.value?.to, object: data.field.value?.object, ...value });
                          }
                        }}
                      />
                    ) : (
                      <Select
                        formatOptionLabel={formatFromColumnOption}
                        isError={!!errors?.[`${name}[${index}].from`]}
                        options={columns}
                        placeholder="Select a column..."
                        value={data.field.value.from}
                        onChange={(option) => {
                          const availableOptions = destinationFieldOptions?.filter((o) => !o.disabled);
                          if (!data.field.value?.to) {
                            data.field.onChange(suggest(option, availableOptions));
                          } else {
                            data.field.onChange({
                              to: data.field.value?.to,
                              object: data.field.value?.object,
                              from: option?.value,
                              fieldType: data.field.value?.fieldType,
                            });
                          }
                        }}
                      />
                    )}

                    <ArrowRightIcon color="base.3" size={16} />
                    <Column>
                      {/* When `creatable` is set, show the CreatableSelect component even when there are no predefined options. */}
                      {options || creatable ? (
                        creatable ? (
                          <Flex sx={{ flexDirection: "column", flex: 1 }}>
                            <CreatableSelect
                              {...selectFieldProps}
                              isClearable
                              empty="Type in a field name and Hightouch will create the field in the destination!"
                              formatCreateLabel={(string) => {
                                return `Create field "${string}"...`;
                              }}
                              formatOptionLabel={formatOptionLabel}
                              isError={!!errors?.[`${name}[${index}].to`]}
                              isValidNewOption={(inputValue, _, selectOptions) => {
                                return !selectOptions.find((v) => v.value === inputValue) && Boolean(inputValue);
                              }}
                              placeholder="Select or add a field..."
                              onCreateOption={(value) => data.field.onChange({ ...data.field.value, to: value || undefined })}
                            />
                            {creatableTypes &&
                              data.field.value?.to &&
                              !options?.find((option: Option) => option.value === data.field.value?.to) && (
                                <Select
                                  options={creatableTypes}
                                  placeholder="Select a field type..."
                                  value={data.field.value?.fieldType}
                                  onChange={(creatableType) => {
                                    data.field.onChange({ ...data.field.value, fieldType: creatableType?.value });
                                  }}
                                />
                              )}
                          </Flex>
                        ) : (
                          <Select
                            {...selectFieldProps}
                            isError={!!errors?.[`${name}[${index}].to`]}
                            placeholder="Select a field..."
                          />
                        )
                      ) : (
                        <Input
                          error={!!errors?.[`${name}[${index}].to`]}
                          placeholder={`Enter a field...`}
                          value={data.field.value.to}
                          onChange={(to) => data.field.onChange({ ...data.field.value, to })}
                        />
                      )}
                      {selectedOption?.type === "REFERENCE" && selectedOption?.referenceObjects?.length > 1 && (
                        <Row sx={{ mt: 2, alignItems: "center" }}>
                          <Text sx={{ color: "base.4", fontSize: 1, fontWeight: "semi", whiteSpace: "nowrap", mr: 2 }}>
                            Linked to:
                          </Text>
                          <Select
                            isError={!!errors?.[`${name}[${index}].to`]}
                            options={referenceObjectOptions}
                            value={data.field.value?.lookup?.object}
                            onChange={(selected) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: { by: null, byType: null, from: undefined, object: selected?.value },
                              });
                            }}
                          />
                        </Row>
                      )}
                    </Column>

                    {required && controlledFields.length <= 1 ? (
                      // needs to exist to satisfy grid
                      <Row></Row>
                    ) : isMappingRequired ? (
                      <Tooltip text="Required" />
                    ) : (
                      <Button variant="plain" onClick={() => remove(index)}>
                        <XIcon color="base.6" size={18} />
                      </Button>
                    )}
                  </>
                );
              }}
            />
          </Fragment>
        ))}
      </Grid>

      <FieldError error={retrieveErrorMessage(errors, controlledFields.length, name)} />

      <Row sx={{ mt: 4 }}>
        <Button variant="secondary" onClick={() => append({ type: "standard" })}>
          Add mapping
        </Button>
        <Button
          disabled={watchAutoSyncColumns}
          sx={{ minWidth: "32px", ml: 1 }}
          tooltip="Suggest mappings"
          variant="text-secondary"
          onClick={() => {
            //if no destination options, fill with column names from source
            const flatColumns = flattenOptions(columns);
            const unmappedOptions = (!options?.length ? flatColumns : options).filter((o) => !isOptionExcluded(o));
            const results = automap(columns, unmappedOptions);
            append(results);
          }}
        >
          <LightningIcon size={16} />
        </Button>
      </Row>
    </Column>
  );
};

export const formatFromColumnOption = (option) => {
  if (typeof option?.value === "object") {
    if (option?.value?.type === "additionalColumnReference") {
      return (
        <Row sx={{ alignItems: "center" }}>
          <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
          <Text>{option.label}</Text>
        </Row>
      );
    }
  }
  return option?.label;
};
