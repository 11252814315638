import { useState } from "react";

import { Grid, Text } from "theme-ui";

import { Modal } from "src/ui/modal";

import { useCreateCloudCredentialV2Mutation } from "../../graphql";
import { Button } from "../../ui/button";
import { Field } from "../../ui/field";
import { AWSIcon } from "../../ui/icons/logo-aws";
import { GCPIcon } from "../../ui/icons/logo-gcp";
import { Input } from "../../ui/input";
import { Selectable } from "../../ui/selectable";
import { Help } from "../help";
import { awsConfigValidator, AWSForm } from "./create-aws";
import { gcpConfigValidator, GCPForm } from "./create-gcp";

export const CreateCredential = ({ defaultProvider = null, onClose }) => {
  const [name, setName] = useState<string>();
  const [provider, setProvider] = useState<string | null>(defaultProvider);

  const [config, setConfig] = useState<{ [key: string]: any }>({});
  const isReady = () => (name && provider && validators[provider] ? validators[provider](config) : false);
  const { mutateAsync: save, isLoading: isCreating } = useCreateCloudCredentialV2Mutation();

  let form = <></>;
  let modalTitle = "Select cloud provider";
  switch (provider) {
    case "aws":
      form = <AWSForm config={config} setConfig={setConfig} />;
      modalTitle = "Configure AWS credentials";
      break;

    case "gcp":
      form = <GCPForm config={config} setConfig={setConfig} />;
      modalTitle = "Configure Google Cloud credentials";
      break;
  }

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isReady()}
            loading={isCreating}
            onClick={() => {
              save({ input: { name: name || "", provider: provider || "", config } });
              onClose();
            }}
          >
            Create
          </Button>
        </>
      }
      sx={{ maxWidth: "650px", width: "100%" }}
      title={modalTitle}
      onClose={onClose}
    >
      {provider ? (
        <Grid gap={6}>
          <Field label="Name">
            <Input value={name} onChange={(name) => setName(name)} />
          </Field>

          <Grid gap={4}>{form}</Grid>
          <Help docs={`${import.meta.env.VITE_DOCS_URL}/integrations/aws`} label={"Cloud Integrations"} sx={{ mt: 6 }} />
        </Grid>
      ) : (
        <Grid gap={4} sx={{ mr: 2, flex: 1 }} width={200}>
          <Selectable
            key={"aws"}
            selected={false}
            sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}
            onSelect={() => {
              setProvider("aws");
            }}
          >
            <AWSIcon />
            <Text sx={{ ml: 2, weight: "bold" }}>Amazon Web Services</Text>
          </Selectable>

          <Selectable
            key={"gcp"}
            selected={false}
            sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}
            onSelect={() => {
              setProvider("gcp");
            }}
          >
            <GCPIcon />
            <Text sx={{ ml: 2, weight: "bold" }}>Google Cloud Platform</Text>
          </Selectable>
        </Grid>
      )}
    </Modal>
  );
};

const validators = {
  aws: awsConfigValidator,
  gcp: gcpConfigValidator,
};
