import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const BookIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M19.2,0h-12C5.9,0,4.7,0.5,3.8,1.4C2.9,2.3,2.4,3.5,2.4,4.8v14.4c0,1.3,0.5,2.5,1.4,3.4S5.9,24,7.2,24h12
	c0.6,0,1.2-0.3,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.7V2.4c0-0.6-0.3-1.2-0.7-1.7C20.4,0.3,19.8,0,19.2,0z M4.8,4.8c0-0.6,0.3-1.2,0.7-1.7
	C6,2.7,6.6,2.4,7.2,2.4h12v12h-12c-0.8,0-1.7,0.2-2.4,0.7V4.8z M7.2,21.6c-0.6,0-1.2-0.3-1.7-0.7c-0.5-0.5-0.7-1.1-0.7-1.7
	c0-0.6,0.3-1.2,0.7-1.7s1.1-0.7,1.7-0.7h12v4.8H7.2z M9.6,7.2h4.8c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.4-0.5,0.4-0.8s-0.1-0.6-0.4-0.8
	c-0.2-0.2-0.5-0.4-0.8-0.4H9.6C9.3,4.8,9,4.9,8.8,5.2C8.5,5.4,8.4,5.7,8.4,6s0.1,0.6,0.4,0.8C9,7.1,9.3,7.2,9.6,7.2z"
      />
    </Icon>
  );
};
