import { FC } from "react";

import { Image, Text } from "theme-ui";

import { IntegrationBadge } from "src/components/integrations/integration-badge";
import { SourceDefinitionFragment as SourceDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Column, Row } from "src/ui/box";
import { Selectable } from "src/ui/selectable";

type Props = {
  definition: SourceDefinition;
  onSelect: (definition: SourceDefinition) => void;
  selected: boolean;
};

export const Source: FC<Readonly<Props>> = ({ definition, onSelect, selected }) => {
  return (
    <Selectable
      selected={selected}
      sx={{ position: "relative", justifyContent: "center", height: "72px", overflow: "visible", p: 4 }}
      onSelect={() => {
        analytics.track("Source Type Clicked", {
          source_type: definition.name,
          source_slug: definition.type,
          source_status: definition.status,
          coming_soon: definition.status === "alpha",
        });
        if (definition.status === "alpha") {
          newIntercomMessage(
            `Hi, I would like to participate in the private preview for the ${definition.name} source. My use case involves syncing _____ from ${definition.name} to _____.`,
          );
        } else {
          onSelect(definition);
        }
      }}
    >
      <Row sx={{ alignItems: "center" }}>
        <Image src={definition.icon} sx={{ width: "28px", maxHeight: "100%", objectFit: "contain", flexShrink: 0 }} />
        <Column sx={{ ml: 4 }}>
          <Text
            sx={{
              fontWeight: "bold",
              wordBreak: "break-word",
            }}
          >
            {definition.name}
          </Text>
        </Column>
      </Row>

      <IntegrationBadge definition={definition} integrationType="source" isSample={definition.isSampleDataSource} />
    </Selectable>
  );
};
