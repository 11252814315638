import { FC, useMemo, useState } from "react";

import * as Sentry from "@sentry/browser";
import { Controller, useForm } from "react-hook-form";
import { Routes, Route, Outlet, useOutletContext, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text, Image } from "theme-ui";

import { alertTypes } from "src/components/alerts";
import alertingImage from "src/components/extensions/assets/alerting.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  AlertingCredentialsQuery,
  ResourcePermissionGrant,
  useAlertingCredentialsQuery,
  useCreatePagerDutyCredentialsMutation,
  useDeleteWorkspaceAlertsMutation,
  useUpdatePagerDutyCredentialsMutation,
  useWorkspaceAlertsWithStatusesQuery,
  WorkspaceAlertsWithStatusesQuery,
} from "src/graphql";
import { Fade } from "src/ui/animations";
import { Column, Row, Wrap } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { AlertingIcon, CheckCircleIcon, PagerDutyIcon, SlackIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { SensitiveField } from "src/ui/sensitive-field";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";
import { formatDatetime } from "src/utils/time";

import { CreateAlert, EditAlert } from "../settings/alerts";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const Alerting: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Your Hightouch syncs can be mission critical to your business. Hightouch lets you create alerts to inform you immediately of any errors with your syncs. Alerts can be sent to Slack, PagerDuty, email or SMS text message."
              icon={AlertingIcon}
              image={alertingImage}
              integrations={[
                { name: "Slack", icon: SlackIcon },
                { name: "PagerDuty", icon: PagerDutyIcon },
              ]}
              subtitle="Get alerted on important sync notifications"
              title="Alerting"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentialsData, isLoading: credentialsLoading } = useAlertingCredentialsQuery();
  const { data: alerts, isLoading: alertsLoading } = useWorkspaceAlertsWithStatusesQuery(undefined, {
    select: (data) => data.alerts,
  });

  const slackCredentials = credentialsData?.slack_credentials?.[0];
  const pagerDutyCredentials = credentialsData?.pagerduty_credentials?.[0];

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Alerting" }]} size="medium">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/alerting");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet
        context={{
          loading: alertsLoading || credentialsLoading,
          alerts,
          pagerDutyCredentials: pagerDutyCredentials,
          slackCredentials: slackCredentials,
        }}
      />
    </Page>
  );
};

type Alerts = WorkspaceAlertsWithStatusesQuery["alerts"];
type Alert = Alerts[0];
type OutletContext = {
  loading: boolean;
  slackCredentials: AlertingCredentialsQuery["slack_credentials"][0];
  pagerDutyCredentials: AlertingCredentialsQuery["pagerduty_credentials"][0];
  alerts: Alerts;
};

enum Modals {
  Delete = "delete",
  Slack = "slack",
  PagerDuty = "pagerduty",
  EditAlert = "edit-alert",
  CreateAlert = "create-alert",
}

const Configuration: FC = () => {
  const { hasPermissions } = useUser();
  const { addToast } = useToasts();
  const { slackCredentials, pagerDutyCredentials, alerts, loading } = useOutletContext<OutletContext>();
  const authorized = hasPermissions([{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]);

  const [modal, setModal] = useState<Modals | undefined>();
  const [selectedAlert, setSelectedAlert] = useState<Alert | undefined>();
  const { selectedRows, onRowSelect } = useRowSelect();

  const { isLoading: loadingBulkDelete, mutateAsync: bulkDelete } = useDeleteWorkspaceAlertsMutation();

  const closeModal = () => setModal(undefined);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        key: "name",
      },
      {
        name: "Type",
        cell: ({ type }) => (
          <Row sx={{ alignItems: "center" }}>
            <Image src={alertTypes[type].icon} sx={{ mr: 2 }} width="18px" />
            <Text>{alertTypes[type].name}</Text>
          </Row>
        ),
      },
      { name: "Default (Fatal)", cell: ({ fatal_error_default }) => (fatal_error_default ? "On" : "Off") },
      { name: "Default (Row)", cell: ({ row_error_default }) => (row_error_default ? "On" : "Off") },
      {
        name: "Last Alerted",
        cell: ({ alert_statuses }) =>
          alert_statuses?.[0]?.last_attempted ? formatDatetime(alert_statuses?.[0]?.last_attempted) : "Never",
      },
    ],
    [],
  );

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Column sx={{ mb: 8 }}>
        <Heading sx={{ mb: 4 }}>Credentials</Heading>
        <Grid columns="350px 350px" gap={6}>
          <Card
            footer={
              authorized ? (
                <Text sx={{ color: "primaries.8", ml: "auto" }}>{slackCredentials ? "Manage" : "Set Up"}</Text>
              ) : undefined
            }
            size="small"
            onClick={authorized ? () => setModal(Modals.Slack) : undefined}
          >
            <Row sx={{ justifyContent: "space-between" }}>
              <Column>
                <Row sx={{ alignItems: "center", mb: 4 }}>
                  <SlackIcon size={24} />
                  <Text sx={{ fontWeight: "bold", fontSize: 2, ml: 2 }}>Slack</Text>
                </Row>

                <Text sx={{ color: "base.5" }}>Send alerts to a specific Slack channel</Text>
              </Column>

              <CheckCircleIcon color={slackCredentials ? "green" : "base.4"} />
            </Row>
          </Card>

          <Card
            footer={
              authorized ? (
                <Text sx={{ color: "primaries.8", ml: "auto" }}>{pagerDutyCredentials ? "Manage" : "Set Up"}</Text>
              ) : undefined
            }
            size="small"
            onClick={authorized ? () => setModal(Modals.PagerDuty) : undefined}
          >
            <Row sx={{ justifyContent: "space-between" }}>
              <Column>
                <Row sx={{ alignItems: "center", mb: 4 }}>
                  <PagerDutyIcon />
                  <Text sx={{ fontWeight: "bold", fontSize: 2, ml: 2 }}>PagerDuty</Text>
                </Row>
                <Text sx={{ color: "base.5" }}>Configure custom PagerDuty alerts</Text>
              </Column>

              <CheckCircleIcon color={pagerDutyCredentials ? "green" : "base.4"} />
            </Row>
          </Card>
        </Grid>
      </Column>

      <Column>
        <Row sx={{ justifyContent: "space-between", alignItems: "center", mb: 4, mt: 6 }}>
          <Heading>Alerts</Heading>

          {authorized && (
            <Wrap spacing={4} sx={{ alignItems: "center" }}>
              <Fade hidden={!selectedRows.length}>
                <Button variant="soft" onClick={() => setModal(Modals.Delete)}>
                  Delete
                </Button>
              </Fade>
              <Button onClick={() => setModal(Modals.CreateAlert)}>Add alert</Button>
            </Wrap>
          )}
        </Row>
        <Table
          columns={columns}
          data={alerts}
          placeholder={{
            title: "No alerts",
            error: "Alerts failed to load, please try again.",
          }}
          selectedRows={selectedRows}
          sx={{ maxHeight: "600px" }}
          onRowClick={
            authorized
              ? (row) => {
                  setSelectedAlert(row);
                  setModal(Modals.EditAlert);
                }
              : undefined
          }
          onSelect={onRowSelect}
        />
      </Column>

      {modal === Modals.EditAlert && (
        <EditAlert
          alert={selectedAlert}
          onClose={() => {
            setSelectedAlert(undefined);
            closeModal();
          }}
        />
      )}
      {modal === Modals.CreateAlert && <CreateAlert onClose={closeModal} />}
      {modal === Modals.Slack && <SlackCredentialsForm onClose={closeModal} />}
      {modal === Modals.PagerDuty && <PagerDutyCredentialsForm onClose={closeModal} />}

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={modal === Modals.Delete}
        label="alert"
        loading={loadingBulkDelete}
        onClose={() => setModal(undefined)}
        onDelete={async () => {
          try {
            await bulkDelete({ ids: selectedRows?.map(String) });
          } catch (e) {
            addToast("There was an error deleting your alerts", { appearance: "error" });
            Sentry.captureException(e);
          }
          onRowSelect([]);
        }}
      />
    </PermissionProvider>
  );
};

const PagerDutyCredentialsForm: FC<Readonly<{ onClose: () => void }>> = ({ onClose }) => {
  const { pagerDutyCredentials } = useOutletContext<OutletContext>();
  const { addToast } = useToasts();
  const { mutateAsync: update, isLoading: loadingUpdate } = useUpdatePagerDutyCredentialsMutation();
  const { mutateAsync: create, isLoading: loadingCreate } = useCreatePagerDutyCredentialsMutation();
  const { control, handleSubmit } = useForm({ defaultValues: { api_key: "" } });

  const saving = loadingUpdate || loadingCreate;

  const submit = async (data) => {
    try {
      if (pagerDutyCredentials?.id) {
        await update({
          id: pagerDutyCredentials.id,
          object: data,
        });
      } else {
        await create({
          object: {
            config: {},
            api_key: data.api_key,
            state: "enabled",
          },
        });
      }

      addToast("Your PagerDuty configuration has been saved.", {
        appearance: "success",
      });
    } catch (e) {
      addToast("There was an error saving your PagerDuty configuration.", {
        appearance: "error",
      });
      Sentry.captureException(e);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={saving} onClick={handleSubmit(submit)}>
            Save
          </Button>
        </>
      }
      sx={{ width: "500px" }}
      title="Configure PagerDuty"
      onClose={onClose}
    >
      {pagerDutyCredentials && (
        <Row sx={{ alignItems: "center", mb: 4 }}>
          <Circle color="green" radius="10px" sx={{ mr: 2 }} />
          <Text sx={{ fontWeight: "bold" }}>Your PagerDuty account is connected</Text>
        </Row>
      )}
      <Text sx={{ color: "base.5", mb: 6 }}>
        Enter your API key for PagerDuty. Visit our <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/pagerduty/`}>docs</Link>{" "}
        to learn more.
      </Text>
      <Field label="PagerDuty API Key">
        <Controller
          control={control}
          name="api_key"
          render={({ field }) => (
            <SensitiveField hideSecret={Boolean(pagerDutyCredentials?.api_key)} value={field.value} onChange={field.onChange} />
          )}
        />
      </Field>
    </Modal>
  );
};

export const SlackCredentialsForm: FC<Readonly<{ onClose: () => void }>> = ({ onClose }) => {
  const { slackCredentials } = useOutletContext<OutletContext>();

  return (
    <Modal
      footer={
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      }
      sx={{ width: "500px" }}
      title="Configure Slack"
      onClose={onClose}
    >
      {slackCredentials && (
        <Row sx={{ alignItems: "center", mb: 4 }}>
          <Circle color="green" radius="10px" sx={{ mr: 2 }} />
          <Text sx={{ fontWeight: "bold" }}>Your Slack account is connected</Text>
        </Row>
      )}
      <Text sx={{ mb: 6, color: "base.5" }}>
        Authenticate Hightouch to Slack to alert your team when a sync experiences an error.
      </Text>
      <Button
        label={slackCredentials ? `Re-authorize Slack` : `Authorize Slack`}
        variant="secondary"
        onClick={() => {
          window.location.href = `${import.meta.env.VITE_API_BASE_URL}/slackv2/oauth/alert`;
        }}
      />
    </Modal>
  );
};
