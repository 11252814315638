import { FC } from "react";

import { Text, ThemeUIStyleObject } from "theme-ui";

import { useContentVisibility } from "src/hooks/use-content-visibility";
import { Row } from "src/ui/box";
import { Tooltip } from "src/ui/tooltip";

type Props = {
  label: { key: string; value: string | number };
  maxKeyWidth?: string;
  maxValueWidth?: string;
  sx?: ThemeUIStyleObject;
};

export const labelStyle: ThemeUIStyleObject = {
  alignItems: "center",
  bg: "base.2",
  borderRadius: 2,
  px: 2,
  height: "28px",
  width: "max-content",
  flexShrink: 0,
  fontSize: 0,
};

export const Label: FC<Readonly<Props>> = ({ label, maxKeyWidth, maxValueWidth, sx = {} }) => {
  const { isVisible: isKeyVisible, ref: keyRef } = useContentVisibility<HTMLDivElement>();
  const { isVisible: isValueVisible, ref: valueRef } = useContentVisibility<HTMLDivElement>();

  return (
    <Row
      sx={{
        ...labelStyle,
        ...sx,
      }}
    >
      <Tooltip disabled={isKeyVisible} text={label.key}>
        <Text
          ref={keyRef}
          sx={{
            color: "primaries.8",
            fontWeight: "bold",
            maxWidth: maxKeyWidth || "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {label.key}
        </Text>
      </Tooltip>
      <Tooltip disabled={isValueVisible} text={label.value.toString()}>
        <Text
          ref={valueRef}
          sx={{
            ml: 2,
            maxWidth: maxValueWidth || "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {String(label.value)}
        </Text>
      </Tooltip>
    </Row>
  );
};
