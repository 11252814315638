import { FC, useState, useMemo } from "react";

import { sha256 } from "js-sha256";
import moment from "moment";
import { Grid } from "theme-ui";
import { v4 as uuidv4 } from "uuid";

import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Settings } from "src/components/settings";
import { useApiKeysQuery, useCreateApiKeyMutation, useDeleteApiKeysMutation } from "src/graphql";
import { Fade } from "src/ui/animations/fade";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

export const APIKeys: FC = () => {
  const [key, setKey] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [createNew, setCreateNew] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  const { data } = useApiKeysQuery();
  const { isLoading: createLoading, mutateAsync: createApiKey } = useCreateApiKeyMutation();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteApiKeysMutation();

  const apiKeys = data?.api_keys;

  const columns = useMemo(
    () => [
      {
        name: "Name",
        key: "name",
      },
      {
        name: "Last Used",
        cell: ({ last_used }) => (last_used ? moment(last_used)?.calendar() : "Never"),
      },
      {
        name: "Created At",
        cell: ({ created_at }) => moment(created_at).calendar(),
      },
    ],
    [],
  );

  return (
    <>
      <Settings route="api-keys">
        <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 3 }}>
          <Heading>API Keys</Heading>

          <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Fade hidden={!selectedRows.length} sx={{ mr: 2 }}>
              <Button variant="soft" onClick={() => setConfirmingDelete(true)}>
                Delete
              </Button>
            </Fade>

            <Button
              label="Add API Key"
              onClick={() => {
                const id = uuidv4();
                setKey(id);
                setName(null);
                setCreateNew(true);
              }}
            />
          </Row>
        </Row>
        <Table
          columns={columns}
          data={apiKeys}
          placeholder={placeholder}
          selectedRows={selectedRows}
          sx={{ maxHeight: "600px" }}
          onSelect={onRowSelect}
        />
      </Settings>

      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setCreateNew(false)}>
              Cancel
            </Button>
            <Button
              disabled={!name}
              label="Create"
              loading={createLoading}
              onClick={async () => {
                if (key) {
                  await createApiKey({
                    object: { name, hash: sha256.create().update(key).hex() },
                  });
                }

                setCreateNew(false);
              }}
            />
          </>
        }
        isOpen={createNew}
        title="Create API Key"
        onClose={() => {
          setCreateNew(false);
        }}
      >
        <Grid gap={8}>
          <Field label="Name">
            <Input
              placeholder={`Enter a name...`}
              value={name}
              onChange={(value) => {
                setName(value);
              }}
            />
          </Field>
          <Field description="This key will only be displayed once, please copy it into your secrets manager." label="API Key">
            <Input readOnly value={key} />
          </Field>
        </Grid>
      </Modal>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="API key"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await bulkDelete({ ids: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />
    </>
  );
};

const placeholder = {
  title: "No API keys",
  error: "API keys failed to load, please try again.",
};
