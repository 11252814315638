import { useCallback, useEffect, useMemo, useState, FC } from "react";

import * as Sentry from "@sentry/browser";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications2";
import { Text } from "theme-ui";

import { AudiencesDemo } from "src/components/audiences/audiences-demo";
import { Filters, getHasuaExpFromFilters } from "src/components/filter";
import { audienceFilterDefinitions } from "src/components/filter/config";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  AudiencesQuery,
  MinimalAudiencesQuery,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAddLabelsToAudiencesMutation,
  useAudienceFiltersQuery,
  useAudiencesQuery,
  useDeleteModelsMutation,
  useMinimalAudiencesQuery,
  useParentModelsQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations/fade";
import { Box, Column, Row } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon, CogIcon, WarningIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Link } from "src/ui/link";
import { Menu, MenuOption } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { useNavigate } from "src/utils/navigate";
import { abbreviateNumber } from "src/utils/numbers";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  UpdatedAt = "updated_at",
}

export const Audiences: FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [search, setSearch] = useQueryState("search");
  const { data: entitlementsData } = useEntitlements(false);
  const audiencesEnabled = entitlementsData.entitlements.audiences;

  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [loading, setLoading] = useState(true);
  const [parentModelWarning, setParentModelWarning] = useState(false);

  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "audience" });

  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToAudiencesMutation();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteModelsMutation();

  const { data: audienceFilterData } = useAudienceFiltersQuery();

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      ...getHasuaExpFromFilters(audienceFilterDefinitions, filters),
      query_type: { _eq: "visual" },
    };

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }

    return hasuraFilters;
  }, [filters, search]);

  const fullAudiencesQuery = useAudiencesQuery(
    {
      filters: hasuraFilters,
      offset,
      limit,
      orderBy,
    },
    {
      enabled: Boolean(filters),
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const incrementalAudiences = useIncrementalQuery<MinimalAudiencesQuery, AudiencesQuery>(
    useMinimalAudiencesQuery(
      {
        offset,
        limit,
        filters: hasuraFilters,
        orderBy,
      },
      {
        refetchInterval: 3000,
        notifyOnChangeProps: "tracked",
        keepPreviousData: true,
      },
    ),
    fullAudiencesQuery,
  );

  const { labels } = useLabels();

  const audiencesLoading = incrementalAudiences.minimalQueryLoading || (loading && incrementalAudiences.minimalQueryRefetching);

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const allAudiences = useMemo(
    () =>
      audienceFilterData?.segments?.map((audience) => ({
        ...audience,
        syncs: audience.syncs.map((sync) => ({
          ...sync,
          destination: {
            ...sync.destination,
            definition: sync.destination ? destinationDefinitions?.[sync.destination.type] : undefined,
          },
        })),
      })),
    [destinationDefinitions, audienceFilterData],
  );

  const audiences = incrementalAudiences?.data?.segments ?? [];
  const audiencesCount = incrementalAudiences?.data?.segments_aggregate?.aggregate?.count ?? 0;

  const { data: parentModelsData, isRefetching: refetchingParentModels } = useParentModelsQuery(undefined, {
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60, // 1 minute
  });
  const parentModels = parentModelsData?.segments ?? [];
  const hasParentModels = parentModels.length > 0;

  const actions: MenuOption[] = [{ label: "Add labels", onClick: () => setAddingLabels(true) }];

  if (userCanDelete) {
    actions.push({
      label: "Delete",
      onClick: () => setConfirmingDelete(true),
      sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
    });
  }

  const bulkDeleteAudiences = async () => {
    if (userCanDelete) {
      try {
        await bulkDelete({ ids: selectedRows.map(String) });
        onRowSelect([]);
      } catch (error) {
        addToast("Failed to delete audiences.", { appearance: "error" });
        Sentry.captureException(error);
      }
    }
  };

  const hideParentModelWarning = () => setParentModelWarning(false);

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, labels }) => (
          <Row sx={{ alignItems: "center" }}>
            <TextWithTooltip sx={{ maxWidth: "350px" }} text={name}>
              {name}
            </TextWithTooltip>
            <LabelsCell labels={labels} />
          </Row>
        ),
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) => (size ? <Text>{abbreviateNumber(size)}</Text> : <Text sx={{ color: "base.4" }}>--</Text>),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.NumSyncs),
        max: "max-content",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationDefinitions ?? []} syncs={syncs} />;
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [destinationDefinitions, orderBy],
  );

  const placeholder = useMemo(
    () => ({
      title: "No audiences",
      body: search
        ? "No audiences match your search"
        : filters?.length
        ? "No audiences match your filters"
        : hasParentModels
        ? "Add an audience to get started"
        : "At least one Parent Model is required to create an Audience",
      button: hasParentModels ? undefined : (
        <Button
          onClick={() => {
            navigate("/audiences/setup/parent-models");
          }}
        >
          Create parent model
        </Button>
      ),
      error: "Audiences failed to load, please try again.",
    }),
    [hasuraFilters, hasParentModels],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/audiences/${id}`, navigate, event), [navigate]);

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, orderBy, hasuraFilters]);

  useEffect(() => {
    if (!incrementalAudiences.minimalQueryRefetching) {
      setLoading(false);
    }
  }, [audiences, incrementalAudiences.minimalQueryRefetching]);

  if (!audiencesEnabled) {
    return <AudiencesDemo />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
        <Page crumbs={[{ label: "Audiences" }]} size="full">
          <Column sx={{ mb: 3, width: "100%" }}>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
              <Row sx={{ alignItems: "center" }}>
                <Heading sx={{ mr: 2 }}>Audiences</Heading>
                <Views
                  deletePermissionResource="audience"
                  value={selectedView}
                  views={views}
                  onChange={selectView}
                  onDelete={deleteView}
                />
                {viewNotSaved &&
                  (selectedView === "Default view" ? (
                    <Button
                      sx={{ ml: 2 }}
                      variant="purple"
                      onClick={() => {
                        setCreateViewModalOpen(true);
                      }}
                    >
                      Save as
                    </Button>
                  ) : (
                    <DropdownButton
                      loading={updatingView}
                      options={[
                        {
                          label: "Save as",
                          onClick: () => {
                            setCreateViewModalOpen(true);
                          },
                        },
                        {
                          label: "Reset changes",
                          onClick: () => {
                            resetViewFilters();
                          },
                        },
                      ]}
                      sx={{ ml: 2 }}
                      onClick={updateCurrentView}
                    >
                      Save
                    </DropdownButton>
                  ))}
              </Row>
              <Row sx={{ alignItems: "center" }}>
                <Permission permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]}>
                  <Button
                    iconAfter={<CogIcon color="base.5" size={14} />}
                    sx={{ mr: 2 }}
                    variant="secondary"
                    onClick={() => navigate(`/audiences/setup/parent-models`)}
                  >
                    Setup
                  </Button>
                </Permission>
                <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Create] }]}>
                  <Button
                    disabled={!hasParentModels}
                    loading={refetchingParentModels}
                    onClick={() => {
                      if (!hasParentModels) {
                        return setParentModelWarning(true);
                      }

                      analytics.track("Add Audience Clicked");
                      navigate("/audiences/new");
                    }}
                  >
                    Add audience
                  </Button>
                </Permission>
              </Row>
            </Row>
            <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Row sx={{ alignItems: "center" }}>
                <SearchInput placeholder={`Search audiences by name...`} value={search ?? ""} onChange={setSearch} />

                <Filters
                  data={allAudiences}
                  filterDefinitions={audienceFilterDefinitions}
                  filters={filters}
                  resourceType="segment"
                  sx={{ ml: 2 }}
                  onChange={updateFilters}
                />
              </Row>

              <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
                <Fade hidden={!selectedRows.length} sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text as="label" sx={{ display: "flex", alignItems: "center" }}>
                      <Text as="span" sx={{ color: "base.5" }}>{`${pluralize(
                        "audience",
                        selectedRows.length,
                        true,
                      )} selected`}</Text>

                      <Menu options={actions}>
                        <Button
                          propagate
                          iconAfter={<ChevronDownIcon size={16} />}
                          loading={loadingBulkDelete}
                          sx={{ ml: 3 }}
                          variant="secondary"
                        >
                          Select action
                        </Button>
                      </Menu>
                    </Text>
                  </Box>
                </Fade>
              </Permission>
            </Row>
          </Column>

          <Table
            columns={columns}
            data={audiences}
            error={Boolean(incrementalAudiences.fullQueryError || incrementalAudiences.minimalQueryError)}
            loading={audiencesLoading}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
          />

          <Pagination count={audiencesCount} label="audiences" page={page} rowsPerPage={limit} setPage={setPage} />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="audience"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteAudiences}
      />
      <EditLabels
        description="You can label audiences that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("audience", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={async (labels) => {
          const labelCount = Object.keys(labels).length;
          await addLabels({ ids: selectedRows.map(String), labels });
          setAddingLabels(false);
          addToast(
            `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
              "audience",
              selectedRows.length,
            )}`,
            {
              appearance: "success",
            },
          );
          onRowSelect([]);
        }}
      />

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />

      <Modal
        bodySx={{ pb: 6 }}
        footer={
          <>
            <Button variant="secondary" onClick={hideParentModelWarning}>
              Go back
            </Button>
            <Link newTab to="/audiences/setup/parent-models/new">
              <Button onClick={hideParentModelWarning}>Add parent model</Button>
            </Link>
          </>
        }
        header={
          <Row sx={{ alignItems: "center" }}>
            <WarningIcon color="yellow" sx={{ mr: 4 }} />
            <Heading variant="h2">No parent models found</Heading>
          </Row>
        }
        isOpen={parentModelWarning}
        sx={{ maxWidth: "400px" }}
        onClose={hideParentModelWarning}
      >
        <Text as="p">
          To use this condition, you need at least one parent model in this workspace. Would you like to create one now?
        </Text>
      </Modal>
    </>
  );
};
