import { FC } from "react";

import { Box, Image, Text, Grid } from "theme-ui";

import { Editor } from "src/components/editor";
import { DbtQuery } from "src/components/explore/preview/dbt-query";
import { TableQuery } from "src/components/explore/preview/table-query";
import GitHubLogo from "src/components/logos/github.svg";
import { CustomQueryView } from "src/components/sources/forms/custom-query";
import { useDbtModelQuery, useDbtSyncModelQuery, useGetLookerLookQuery, ModelQuery, ObjectQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { LookerIcon } from "src/ui/icons";
import { Section } from "src/ui/section";
import { QueryType } from "src/utils/models";
import { useSource } from "src/utils/sources";

import { LookerQuery } from "../explore/preview/looker-query";
import { VisualQuery } from "../explore/preview/visual-query";
import { Permission } from "../permission";

type Props = {
  model?: ((ModelQuery["segments_by_pk"] | ObjectQuery["segments_by_pk"]) & { parent?: { id: string } }) | null;
  onEdit?: () => void;
};

export const Query: FC<Readonly<Props>> = ({ model, onEdit }) => {
  const type = model?.query_type;
  const dbtModelId = model?.query_dbt_model_id;
  const lookerLookId = model?.query_looker_look_id;

  const { data: source } = useSource(model?.connection?.id);

  const { data: dbtModelQuery } = useDbtModelQuery(
    {
      id: Number(dbtModelId),
    },
    { enabled: Boolean(dbtModelId && type === QueryType.Dbt) },
  );

  const { data: dbtSyncModelQuery } = useDbtSyncModelQuery(
    {
      id: String(dbtModelId),
    },
    { enabled: Boolean(dbtModelId && type === QueryType.DbtModel) },
  );

  const { data: lookerLookQuery } = useGetLookerLookQuery(
    {
      id: lookerLookId ?? "",
      withSql: true,
    },
    { enabled: Boolean(lookerLookId && type === QueryType.LookerLook) },
  );

  return (
    <Section
      header={
        <Row sx={{ alignItems: "center", justifyContent: "space-between", flex: 1 }}>
          <Heading>
            <Row>
              {type === QueryType.Dbt || type === QueryType.DbtModel
                ? "dbt Model"
                : type === QueryType.LookerLook
                ? "This model is based on a Look"
                : "Query"}
              {type === QueryType.LookerLook && <LookerIcon size={24} />}
            </Row>
          </Heading>
          {onEdit && (
            <Permission>
              <Button variant="secondary" onClick={onEdit}>
                Edit
              </Button>
            </Permission>
          )}
        </Row>
      }
      sx={{ flex: 1 }}
    >
      {type === QueryType.Visual && (
        <Box sx={{ p: 2, border: "small", borderRadius: 1 }}>
          <VisualQuery model={model} />
        </Box>
      )}
      {type === QueryType.Table && (
        <Box sx={{ p: 2, border: "small", borderRadius: 1 }}>
          <TableQuery table={model?.query_table_name ?? ""} />
        </Box>
      )}
      {type === QueryType.RawSql && (
        <Box sx={{ border: "small", borderRadius: 1 }}>
          <Editor readOnly language="sql" value={model?.query_raw_sql ?? ""} />
        </Box>
      )}
      {type === QueryType.DbtModel && dbtSyncModelQuery?.dbt_sync_models_by_pk && (
        <DbtQuery model={dbtSyncModelQuery?.dbt_sync_models_by_pk} />
      )}
      {type === QueryType.Dbt && (
        <Grid gap={3}>
          <Field inline label="Name">
            <Text variant="subtle">{dbtModelQuery?.dbt_models_by_pk?.alias || dbtModelQuery?.dbt_models_by_pk?.name}</Text>
          </Field>

          {dbtModelQuery?.dbt_models_by_pk?.description && (
            <Field inline label="Description">
              <Text variant="subtle">{dbtModelQuery?.dbt_models_by_pk?.description}</Text>
            </Field>
          )}

          {dbtModelQuery?.dbt_models_by_pk?.original_file_path &&
            dbtModelQuery?.dbt_models_by_pk?.git_sync_config?.repository?.includes("github.com") && (
              <Field inline label="Source">
                <a
                  href={`${dbtModelQuery?.dbt_models_by_pk?.git_sync_config?.repository}/blob/master/${dbtModelQuery?.dbt_models_by_pk?.original_file_path}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    sx={{
                      p: 3,
                      alignSelf: "start",
                    }}
                    variant="secondary"
                  >
                    <Image src={GitHubLogo} sx={{ height: 25 }} />
                  </Button>
                </a>
              </Field>
            )}
        </Grid>
      )}
      {type === QueryType.LookerLook && lookerLookQuery?.getLookerLook?.look && (
        <LookerQuery look={lookerLookQuery?.getLookerLook?.look} />
      )}
      {type === QueryType.Custom && model && source && <CustomQueryView query={model.custom_query} source={source} />}
    </Section>
  );
};
