import { FC } from "react";

import { ModelQuery } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { WizardOnboarding } from "src/ui/wizard";
import { UseSourceResult } from "src/utils/sources";

import { useCreateModelWizard } from "../models/use-create-model-wizard";

type Source = NonNullable<UseSourceResult["data"]>;

type Props = {
  model?: ModelQuery["segments_by_pk"];
  source?: Source;
  onCancel(): void;
  onSubmit({ id, type }: { id: string; type: string | undefined }): void;
};

export const OnboardingCreateModel: FC<Readonly<Props>> = ({ model, source, onCancel, onSubmit }) => {
  const { createModel, loading, setStep, step, steps } = useCreateModelWizard({
    model,
    source,
    onSubmit: (args) => {
      onSubmit(args);
    },
  });

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <WizardOnboarding
      setStep={setStep}
      step={step}
      steps={steps}
      title="Set up Model"
      onCancel={onCancel}
      onSubmit={createModel}
    />
  );
};
