import { FC, useState, useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { parseISO } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { GitCredentials, useCreateGitCredentialsMutation, useUpdateGitCredentialsMutation } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";

import { TunnelSelect } from "../tunnels/tunnel-select";

const serviceOptions = [
  { label: "GitHub App", value: "github_app" },
  { label: "GitHub", value: "github" },
  { label: "Gitlab", value: "gitlab" },
  { label: "Bitbucket", value: "bitbucket" },
  { label: "Other", value: "other" },
];

enum Protocol {
  SSH = "ssh",
  HTTP = "http",
}

const GITHUB_PERMISSIONS_LAST_CHANGED_AT = "2022-09-16";
export const githubCredentialsNeedUpdating = (credentials: GitCredentials | undefined) => {
  if (!credentials) {
    return false;
  }
  if (credentials.type !== "github_app") {
    return false;
  }
  return !credentials.updated_at || parseISO(credentials.updated_at) < new Date(GITHUB_PERMISSIONS_LAST_CHANGED_AT);
};

const protocolOption = [
  { label: "HTTP", value: Protocol.HTTP },
  { label: "SSH", value: Protocol.SSH },
];

interface Props {
  isSetup: boolean;
  credentials: GitCredentials | undefined;
  page: "git-sync" | "dbt-models";
}

export const GitCredentialsFields: FC<Readonly<Props>> = ({ credentials, isSetup, page }) => {
  const { addToast } = useToasts();
  const formMethods = useForm();
  const permission = usePermission();

  const { mutateAsync: create } = useCreateGitCredentialsMutation();
  const { mutateAsync: update } = useUpdateGitCredentialsMutation();

  const {
    register,
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = formMethods;

  const type = watch("type");
  const username = watch("username");
  const password = watch("password");
  const sshPrivateKey = watch("ssh_privatekey");

  const submit = async (data) => {
    try {
      if (!credentials?.id) {
        await create({ object: data });
      } else {
        await update({
          object: {
            ...data,
            id: credentials.id.toString(),
          },
        });
      }
      addToast("Configuration saved!", {
        appearance: "success",
      });
      setShowModal(false);
    } catch (e) {
      addToast("There was an error saving your configuration.", {
        appearance: "error",
      });
      Sentry.captureException(e);
    }
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [protocol, setProtocol] = useState<Protocol>(!credentials || credentials?.username ? Protocol.HTTP : Protocol.SSH);

  useEffect(() => {
    if (username || password) {
      setValue("ssh_privatekey", "");
    }
  }, [username, password]);

  useEffect(() => {
    if (sshPrivateKey) {
      setValue("username", "");
      setValue("password", "");
    }
  }, [sshPrivateKey]);

  useEffect(() => {
    reset({
      id: credentials?.id,
      tunnel_id: credentials?.tunnel_id,
      username: credentials?.username || "",
      password: "",
      ssh_privatekey: "",
      type: credentials?.type || "github_app",
    });
  }, [credentials]);

  return (
    <>
      <Card size="small" onClick={permission?.unauthorized ? undefined : () => setShowModal(true)}>
        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text>Git credentials</Text>
          {
            <Text sx={{ color: "primaries.8" }}>
              {permission?.unauthorized ? "Contact your workspace admin" : credentials?.id ? "Manage" : "Set up"}
            </Text>
          }
        </Row>
      </Card>
      {githubCredentialsNeedUpdating(credentials) && (
        <Message variant="warning">
          Your GitHub app permissions are out of date - please update your GitHub app installation to receive the latest
          features.
        </Message>
      )}
      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button disabled={!isDirty} loading={isSubmitting} onClick={handleSubmit(submit)}>
              Save
            </Button>
          </>
        }
        isOpen={showModal}
        sx={{ width: "500px" }}
        title="Git credentials"
        onClose={() => setShowModal(false)}
      >
        <Grid gap={8}>
          <Field label="Git Service">
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select
                  options={serviceOptions}
                  placeholder="Select a service..."
                  value={field.value}
                  onChange={(selected) => {
                    field.onChange(selected?.value);
                  }}
                />
              )}
            />

            {type === "github_app" &&
              (credentials ? (
                <Card size="small" sx={{ mt: 4 }}>
                  <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                    <Row sx={{ alignItems: "center" }}>
                      <Circle color="green" radius="12px" />
                      <Text sx={{ ml: 2 }}>GitHub App ID: {credentials?.credentials?.installationId}</Text>
                    </Row>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        window.location.href = `${import.meta.env.VITE_API_BASE_URL}/github/oauth/integration?page=${page}`;
                      }}
                    >
                      Configure GitHub App
                    </Button>
                  </Row>
                </Card>
              ) : (
                <Card size="small" sx={{ mt: 4 }}>
                  <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                    <Text>Install the Hightouch GitHub App</Text>
                    <Button
                      variant="dark"
                      onClick={() => {
                        window.location.href = `${import.meta.env.VITE_API_BASE_URL}/github/oauth/integration`;
                      }}
                    >
                      Install App
                    </Button>
                  </Row>
                </Card>
              ))}
          </Field>
          {type !== "github_app" && (
            <>
              <Controller
                control={control}
                name="tunnel_id"
                render={({ field }) => (
                  <TunnelSelect
                    optional
                    value={field.value ? { id: field.value } : undefined}
                    onChange={(value) => {
                      field.onChange(value?.id);
                    }}
                  />
                )}
              />

              <Field label="Protocol">
                <Select
                  options={protocolOption}
                  placeholder="Select Protocol"
                  value={protocol}
                  onChange={(selected) => {
                    setProtocol(selected?.value);
                  }}
                />
              </Field>
              {protocol === Protocol.HTTP ? (
                <>
                  <Field label="Username">
                    <Input {...register("username")} disabled={!type} />
                  </Field>
                  <Field label="Token">
                    <Controller
                      control={control}
                      name="password"
                      render={({ field }) => (
                        <SensitiveField hideSecret={isSetup} value={field.value} onChange={field.onChange} />
                      )}
                    />
                  </Field>
                </>
              ) : (
                <Field description="Access git server with ssh protocol" label="SSH Private key">
                  <TextArea placeholder={isSetup ? "<REDACTED>" : undefined} rows={10} {...register("ssh_privatekey")} />
                </Field>
              )}
            </>
          )}
        </Grid>
      </Modal>
    </>
  );
};
