import { useEffect, FC } from "react";

import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { useNavigate } from "src/utils/navigate";
import { useQueryString } from "src/utils/use-query-string";

import { useCreateSourceWizard } from "./use-create-source-wizard";

export const CreateSource: FC = () => {
  const navigate = useNavigate();

  const { createSource, loading, step, steps, setStep, id } = useCreateSourceWizard({
    onSubmit: ({ id }) => navigate(`/sources/${id}`),
  });

  const {
    data: { id: idFromQueryString },
  } = useQueryString();

  useEffect(() => {
    if (idFromQueryString && localStorage.getItem("onboardingSourceOauth")) {
      localStorage.removeItem("onboardingSourceOauth");
      navigate(`/onboarding?onboardingSourceId=${idFromQueryString}`);
    }
  }, [idFromQueryString]);

  if (loading) {
    return <PageSpinner />;
  }
  return (
    <Wizard
      previousDisabled={Boolean(id)}
      setStep={setStep}
      step={step}
      steps={steps}
      title="Add a new source"
      onCancel={() => {
        navigate("/sources");
      }}
      onSubmit={createSource}
    />
  );
};
