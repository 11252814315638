import { useEffect, useState, useRef } from "react";

import { useInfiniteHits } from "react-instantsearch-hooks-web";
import { useToasts } from "react-toast-notifications2";
import { Grid } from "theme-ui";
import { useDebounce } from "use-debounce";

import { DestinationsCatalog } from "src/components/destinations/catalog/destinations-catalog";
import { SetupForm } from "src/components/destinations/destination-form";
import {
  TestDestinationBadge,
  TestNewDestinationButton,
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import { Header, SidebarForm } from "src/components/page";
import { Slug } from "src/components/slug";
import { useUser } from "src/contexts/user-context";
import {
  useIsResourceSlugAvailableQuery,
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateDestinationV2Mutation,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Step } from "src/ui/wizard/wizard";
import { useDestination, useDestinations } from "src/utils/destinations";
import { generateSlug } from "src/utils/slug";
import { useQueryString } from "src/utils/use-query-string";

type CreateDestinationWizardArgs = {
  destinationDefinition?: DestinationDefinition;
  step: number;
  onConnectClick?(defintion: DestinationDefinition): void;
  onSubmit({ id, definition }: { id?: string; definition: DestinationDefinition }): void;
  setStep(step: number): void;
  catalogSidebarTop: number;
  isOnboarding?: boolean;
};

type Config = Record<string, unknown>;

export const useCreateDestinationWizard = ({
  destinationDefinition: externalDestinationDefinition,
  onConnectClick,
  onSubmit,
  step,
  setStep,
  catalogSidebarTop,
  isOnboarding = false,
}: CreateDestinationWizardArgs) => {
  const { user } = useUser();
  const { addToast } = useToasts();
  const {
    data: { id: _destinationId, onboardingDestinationId },
    loading: loadingParams,
  } = useQueryString();

  const destinationId = onboardingDestinationId || _destinationId;

  const [name, _setName] = useState<string>();
  const [slug, _setSlug] = useState<string>("");
  const [dirtySlug, setDirtySlug] = useState<boolean>(false);
  const [config, setConfig] = useState<Config | undefined>();
  const [oAuthSuccess, setOAuthSuccess] = useState<boolean>(false);
  const [credentialId, setCredentialId] = useState<string | undefined>();

  const { mutateAsync: createDestination, isLoading: creating } = useCreateDestinationV2Mutation();
  const { mutateAsync: updateDestination, isLoading: updating } = useUpdateDestinationV2Mutation();

  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testing, setTesting] = useState<boolean>(false);
  const [testError, setTestError] = useState<Error | null>(null);

  // Retrieve the full catalog of destinations for Step 1, not needed
  // if the destination already exists
  const {
    data: { definitions },
    loading: loadingCatalog,
    error: catalogError,
  } = useDestinations();

  // Retrieve the destination if already exists (Steps 2 and 3)
  const {
    data: { destination, definition: destinationDefinition },
    loading: loadingDestination,
  } = useDestination(destinationId ?? "", {
    pause: !destinationId,
  });

  // Definition selected when creating the destination (Step 1)
  const [definition, setDefinition] = useState<DestinationDefinition | null>(null);

  // Algolia hit selected when creating the destination (Step 1)
  const hit = useRef<any>(null);
  const { sendEvent } = useInfiniteHits();

  // Or if the destination is already created
  useEffect(() => {
    if (destination && destinationDefinition) {
      setDefinition(destinationDefinition);
      setOAuthSuccess(true);
      setStep(1);
    }
  }, [destination, destinationDefinition]);

  // Onboarding: set definition if it changes externally
  useEffect(() => {
    if (externalDestinationDefinition) {
      setDefinition(externalDestinationDefinition);
    }
  }, [externalDestinationDefinition]);

  useEffect(() => {
    if (testResult !== TestResult.Unknown) {
      analytics.track("Destination Config Tested", {
        test_successful: testResult === TestResult.Success,
        error_message: `${testError}`,
      });
    }
  }, [testResult]);

  // Set the name and slug of the destination automatically
  useEffect(() => {
    const slugPrefix = [
      definition?.name,
      // TODO: come up with a better naming convention
      // config?.host,
      // config?.hostname,
      // config?.url,
      // config?.database,
      // config?.username,
      // config?.user,
      // config?.region,
    ]
      .filter((d) => d)
      .slice(0, 3)
      .join(" ");
    setName(slugPrefix);
  }, [definition, config]);

  const [debouncedSlug, { isPending: slugDebouncePending }] = useDebounce(slug, 300);
  const { data, isLoading: loadingSlug } = useIsResourceSlugAvailableQuery(
    {
      resourceType: "destinations",
      slug: debouncedSlug,
    },
    { enabled: Boolean(slug) },
  );

  const available = data?.isResourceSlugAvailable && !slugDebouncePending();

  const setSlug = (value) => {
    _setSlug(value);
    setDirtySlug(true);
  };

  const setName = (value) => {
    _setName(value);
    if (!dirtySlug) {
      _setSlug(generateSlug(value));
    }
  };

  const create = async () => {
    let id;
    if (oAuthSuccess && destinationId) {
      const { updateDestinationWithSecrets } = await updateDestination({
        id: destinationId,
        object: {
          config: {
            ...destination?.config,
            ...config,
          },
          name: name,
          slug: slug,
          setup_complete: true,
          created_by: user?.id != null ? String(user?.id) : undefined,
        },
      });
      id = updateDestinationWithSecrets?.id;
    } else {
      const object = {
        config,
        name: name,
        slug: slug,
        setup_complete: true,
        created_by: user?.id != null ? String(user?.id) : undefined,
        type: definition?.type,
      };

      if (credentialId) {
        object["credential_id"] = credentialId;
        if (!config) {
          object["config"] = {};
        }
      }

      const { createDestinationWithSecrets } = await createDestination({
        object: object,
      });

      id = createDestinationWithSecrets?.id;
    }

    addToast(`Destination ${name || definition?.name} created!`, {
      appearance: "success",
    });

    analytics.track("Destination Created", {
      destination_id: id,
      destination_name: name,
      destination_type: definition?.name,
    });

    const definitionToSubmit = definition || destinationDefinition;

    if (definitionToSubmit) {
      onSubmit?.({ id, definition: definitionToSubmit });
    }
  };

  const steps: Step[] = [
    {
      title: "Select",
      disabled: !definition || definition?.status === "alpha" || definition?.status === "shadow",
      render: () => (
        <DestinationsCatalog
          destinationDefinitions={definitions ?? []}
          error={catalogError}
          selection={definition}
          sidebarTop={catalogSidebarTop}
          onSelect={(selectedDefinition, selectedHit) => {
            setDefinition(selectedDefinition);
            hit.current = selectedHit;
          }}
        />
      ),
      onContinue: () => {
        if (hit.current) {
          sendEvent("conversion", hit.current, "Destination Catalog Continue Clicked");
        }
        if (definition) {
          analytics.track("Destination Catalog Continue Clicked", {
            destination_name: definition.name,
            destination_slug: definition.type,
            destination_status: definition.status,
          });
        }
        setStep(1);
      },
      pageSize: "large",
    },
    {
      pageSize: "medium",
      title: `Connect`,
      disabled: destination?.id ? !oAuthSuccess : !config && !credentialId,
      loading: testing || (isOnboarding && (creating || updating)),
      //Use original validation and continue button for special forms on frontend, EX: (Google Sheets SA)
      //Will delete as soon as frontend forms get converted to backend destination configuration.
      onContinue: () => {
        if (oAuthSuccess) {
          setStep(2);
        }
      },
      continueProps: !oAuthSuccess ? { form: "destination-form", type: "submit" } : undefined,
      render: () => (
        <>
          {definition && (
            <>
              <Header
                icon={definition.icon}
                rightToolbar={[<TestDestinationBadge key={0} result={testResult} testing={testing} />]}
                title={`Connect ${definition.name}`}
              />
              <Row sx={{ alignItems: "flex-start" }}>
                <Grid mr={8} sx={{ width: "100%", alignItems: "flex-start" }}>
                  <SetupForm
                    config={config}
                    credentialId={credentialId}
                    definition={definition}
                    destination={destination}
                    disableAuthMethod={Boolean(destinationId)}
                    error={testError}
                    isSetup={true}
                    setConfig={setConfig}
                    setCredentialId={setCredentialId}
                    onConnectClick={onConnectClick}
                    onSubmit={() => {
                      setStep(step + 1);
                      return Promise.resolve();
                    }}
                  />
                </Grid>
                <SidebarForm
                  hideCompliance
                  buttons={
                    definition?.testConnection &&
                    (!destination?.id ? (
                      <TestNewDestinationButton
                        configuration={config}
                        credentialId={credentialId}
                        definition={definition}
                        result={testResult}
                        onError={setTestError}
                        onResult={setTestResult}
                      />
                    ) : (
                      <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <TestUpdatedDestinationButton
                          buttonProps={{ mb: 0, width: "unset" }}
                          credentialId={credentialId}
                          destinationId={destination.id}
                          newConfiguration={config}
                          onError={setTestError}
                          onLoading={setTesting}
                          onResult={setTestResult}
                        />
                      </Row>
                    ))
                  }
                  docsUrl={definition.docs ?? ""}
                  name={definition.name}
                />
              </Row>
            </>
          )}
        </>
      ),
    },
  ];

  if (!isOnboarding) {
    steps.push({
      pageSize: "small",
      title: "Finish",
      disabled: !name || !slug || !available,
      loading: creating || updating,
      render: () => (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Finalize settings
          </Heading>
          <Row sx={{ alignItems: "flex-start" }}>
            <Grid gap={12} mr={8} sx={{ flexGrow: 1 }}>
              <Field label="Name">
                <Input value={name} onChange={(value) => setName(value)} />
              </Field>
              <Field label="Slug">
                <Slug
                  available={Boolean(available)}
                  loading={loadingSlug || slugDebouncePending()}
                  placeholder={"your-destination-slug"}
                  value={slug}
                  onChange={setSlug}
                />
              </Field>
            </Grid>
          </Row>
        </>
      ),
    });
  }

  return {
    createDestination: create,
    definition,
    hit: hit.current,
    loading: loadingParams || loadingCatalog || loadingDestination,
    oAuthSuccess,
    steps,
  };
};
