import { useEffect, FC } from "react";

import { Box, Flex, Text, ThemeUIStyleObject } from "theme-ui";

import { Indices } from "../../../../design";

export type Tab =
  | {
      render?: FC;
      value: string;
    }
  | string;

export interface TabProps {
  tabs: Tab[];
  tab: string | undefined;
  setTab: (tab: Tab) => void;
  sx?: ThemeUIStyleObject;
}

export const Tabs: FC<Readonly<TabProps>> = ({ tabs, tab, setTab, sx = {} }) => {
  useEffect(() => {
    if (tabs[0] && tab && !tabs.map((tab) => (typeof tab === "string" ? tab : tab.value)).includes(tab)) {
      setTab(tabs[0]);
    }
  }, [tabs]);
  return (
    <Flex
      sx={{
        borderBottom: "small",
        borderColor: "base.2",
        flexDirection: "row",
        width: "100%",
        bg: "white",
        zIndex: Indices.Content,
        ...sx,
      }}
    >
      {tabs.map((current) => {
        const value = typeof current === "string" ? current : current.value;
        const isSelected = value === tab;

        return (
          <Box
            key={value}
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                userSelect: "none",
                px: 3,
                py: 2,
                ":hover": { bg: isSelected ? undefined : "base.0" },
                color: isSelected ? "primary" : "base.7",
                cursor: "pointer",
              }}
              onClick={() => {
                setTab(value);
              }}
            >
              <Text
                sx={{
                  fontWeight: "semi",
                  fontSize: 1,
                }}
              >
                {typeof current === "string" ? current : current?.render?.({})}
              </Text>
            </Box>
            {isSelected && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-1px",
                  borderBottom: "2px solid",
                  borderColor: "primary",
                  width: "100%",
                }}
              />
            )}
          </Box>
        );
      })}
    </Flex>
  );
};
