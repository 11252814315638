import { FC, useState, useMemo, useEffect } from "react";

import { Flex, Text } from "theme-ui";

import { Query } from "src/components/models/query";
import { useDraft } from "src/contexts/draft-context";
import { Row } from "src/ui/box";
import { SearchInput } from "src/ui/input";
import { Spinner } from "src/ui/loading";
import { Section } from "src/ui/section";
import { Placeholder } from "src/ui/table/placeholder";

type ModelSelectProps = {
  onClick: (id: any) => void;
  models: any;
  model: any;
  selection: any;
  error: any;
  modelLoading: boolean;
};

export const ModelSelect: FC<Readonly<ModelSelectProps>> = ({ onClick, models, selection, model, modelLoading, error }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { setEditingDraft } = useDraft();

  useEffect(() => {
    setEditingDraft(true);
  }, []);

  const filteredModels = useMemo(() => {
    if (!Array.isArray(models)) {
      return [];
    }

    const regex = new RegExp(searchQuery, "i");

    return models.filter((model) => {
      return regex.test(model.name);
    });
  }, [models, searchQuery]);

  if (error || !models?.length) {
    return (
      <Section bodySx={{ pt: 16 }} sx={{ width: "100%", maxWidth: "100%" }}>
        <Placeholder
          content={{
            title: "No models",
            body: "Add a model to get started",
            error: "Models failed to load, please try again.",
          }}
          error={Boolean(error)}
        />
      </Section>
    );
  }

  return (
    <Row sx={{ minHeight: "400px", width: "100%" }}>
      <Section
        bodySx={{ p: 0, overflowY: "auto", maxHeight: "600px" }}
        header={
          <SearchInput
            placeholder="Search models and audiences by name..."
            sx={{ width: "100%" }}
            value={searchQuery}
            onChange={setSearchQuery}
          />
        }
        sx={{ flex: 1, mr: 8, maxWidth: "360px" }}
      >
        <Flex sx={{ flexDirection: "column", py: 2, height: "100%", maxWidth: "360px" }}>
          <>
            {filteredModels.length === 0 && <Text>No models or audiences were found.</Text>}

            {filteredModels.length > 0 &&
              filteredModels.map(({ id, name }) => (
                <Flex
                  key={id}
                  sx={{
                    cursor: "pointer",
                    bg: selection == id ? "primary" : "white",
                    color: selection == id ? "white" : "inherit",
                    p: 2,
                    ":hover": { bg: selection == id ? "primary" : "primaries.12" },
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => onClick(id)}
                >
                  {name}
                </Flex>
              ))}
          </>
        </Flex>
      </Section>

      {modelLoading ? (
        <Flex sx={{ alignItems: "center", justifyContent: "center", height: "100%", flexGrow: 1 }}>
          <Spinner size={64} />
        </Flex>
      ) : (
        <Query model={model} />
      )}
    </Row>
  );
};
