import { FC } from "react";

import { Flex, Grid, Image, Text } from "theme-ui";

import AudenicesIcon from "src/components/icons/audiences.svg";
import CustomAlertingIcon from "src/components/icons/customalerting.svg";
import CustomStorageIcon from "src/components/icons/customstorage.svg";
import DataDogIcon from "src/components/icons/datadogmonitoring.svg";
import DBTCloudIcon from "src/components/icons/dbtcloud.svg";
import DBTGitRepoIcon from "src/components/icons/dbtgitrepo.svg";
import GitSyncIcon from "src/components/icons/gitsync.svg";
import MultiRegionIcon from "src/components/icons/multiregion.svg";
import NotifyIcon from "src/components/icons/notify.svg";
import PermissionsIcon from "src/components/icons/permissions.svg";
import SSHIcon from "src/components/icons/ssh.svg";
import SsoIcon from "src/components/icons/sso.svg";
import { useUser } from "src/contexts/user-context";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Badge } from "src/ui/badge";
import { Box, Column, Row } from "src/ui/box";
import { CheckCircleIcon } from "src/ui/icons";
import { Label } from "src/ui/label";
import { Link } from "src/ui/link";

type FeatureSection = {
  label: string;
  features: Feature[];
};

type Feature = {
  label: string;
  icon: string;
  businessTier?: boolean;
  disabled?: boolean;
  href?: string;
};

const getFeatureCatalog: (
  audiencesEnabled: boolean,
  externalStorageEnabled: boolean,
  ssoEnabled: boolean,
) => FeatureSection[] = (audiencesEnabled, externalStorageEnabled, ssoEnabled) => [
  {
    label: "Data Activation Apps",
    features: [
      {
        label: "Hightouch Audiences",
        businessTier: true,
        disabled: !audiencesEnabled,
        icon: AudenicesIcon,
        href: "/audiences",
      },
      {
        label: "Hightouch Notify",
        icon: NotifyIcon,
        href: "/destinations",
      },
    ],
  },
  {
    label: "Enterprise Management",
    features: [
      {
        label: "SSO",
        businessTier: true,
        disabled: !ssoEnabled,
        icon: SsoIcon,
      },
      {
        label: "Permissions",
        icon: PermissionsIcon,
      },
      {
        label: "Multi-region",
        icon: MultiRegionIcon,
        href: "/settings/workspace",
      },
      {
        label: "Custom storage",
        businessTier: true,
        icon: CustomStorageIcon,
        disabled: !externalStorageEnabled,
        href: "/settings/storage",
      },
      {
        label: "SSH Tunneling",
        icon: SSHIcon,
        href: "/settings/tunnels",
      },
    ],
  },
  {
    label: "Integrations",
    features: [
      {
        label: "Git Sync",
        icon: GitSyncIcon,
        href: "/extensions/git-sync",
      },
      {
        label: "dbt Cloud Triggers",
        icon: DBTCloudIcon,
        href: "/extensions/dbt-cloud",
      },
      {
        label: "dbt Model Selection",
        icon: DBTGitRepoIcon,
        href: "/extensions/dbt-models",
      },
      {
        label: "Datadog Monitoring",
        icon: DataDogIcon,
        href: "/extensions/monitoring",
      },
      {
        label: "Custom Alerting",
        icon: CustomAlertingIcon,
        href: "/extensions/alerting",
      },
    ],
  },
];

const FeatureBlock: FC<{ feature: Feature }> = ({ feature }) => (
  <Flex sx={{ alignItems: "center", width: "430px", height: "100%", color: "#0F1825F2" }}>
    <Image src={feature.icon} sx={{ width: "30px" }} />
    <Text className={"label"} sx={{ fontWeight: "bold", ml: 4 }}>
      {feature.label}
    </Text>
    {feature.businessTier && (
      <Box sx={{ marginLeft: "8px" }}>
        <Badge
          sx={{ height: "20px", bg: "white", color: "rgba(15, 24, 37, 0.6)" }}
          tooltip="This feature is available for business tier customers."
        >
          Business Tier
        </Badge>
      </Box>
    )}
  </Flex>
);

export const FeatureCatalog: FC = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const { organization } = useUser();
  const featureCatalog = getFeatureCatalog(
    entitlementsData.entitlements.audiences,
    entitlementsData.entitlements.externalStorage,
    !!organization,
  );

  return (
    <Grid gap={12}>
      {featureCatalog.map((featureSection) => (
        <Box key={featureSection.label}>
          <Label
            sx={{
              marginBottom: 0,
              fontWeight: 600,
              fontSize: "11px",
              lineHeight: "12px",
              letterSpacing: "0.4px",
              textTransform: "uppercase",
              color: "rgba(15, 24, 37, 0.42)",
            }}
          >
            {featureSection.label}
          </Label>
          <Column>
            {featureSection.features.map((feature) => (
              <Row
                key={feature.label}
                sx={{
                  height: "48px",
                  borderBottom: "1px #E6EAF0 solid",
                  ":hover .label": { textDecoration: feature.href ? "underline" : "" },
                }}
              >
                {feature.href ? (
                  <Link to={feature.href}>
                    <FeatureBlock feature={feature} />
                  </Link>
                ) : (
                  <FeatureBlock feature={feature} />
                )}
                <Flex sx={{ alignItems: "center", width: "430px" }}>
                  {feature.disabled ? <Text>Not included</Text> : <CheckCircleIcon color={"primary"} />}
                </Flex>
              </Row>
            ))}
          </Column>
        </Box>
      ))}
    </Grid>
  );
};
