import { FC, useMemo } from "react";

import { Box, Text } from "theme-ui";

import { Tooltip } from "../tooltip";

export interface AvatarProps {
  /**
   * User's full name
   */
  name: string | undefined | null;
}

export const Avatar: FC<AvatarProps> = ({ name }) => {
  if (!name) {
    return null;
  }

  const initials = useMemo(() => {
    const parts = name.split(" ");
    const first = parts.shift();
    const last = parts.pop();

    if (!first) {
      return "?";
    }

    if (!last) {
      return first[0];
    }

    return (first[0] ?? "") + (last[0] ?? "");
  }, [name]);

  return (
    <Tooltip portal sx={{ cursor: "initial" }} text={name} theme="light">
      <Box
        sx={{
          width: 28,
          height: 28,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          bg: "base.1",
        }}
      >
        <Text sx={{ fontSize: 12, fontWeight: "bold", color: "base.6" }}>{initials}</Text>
      </Box>
    </Tooltip>
  );
};
