import { useMemo, useState, FC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";
import { Grid, Text, ThemeUIStyleObject } from "theme-ui";

import { FeedbackMenu } from "src/components/app/feedback-menu";
import { InviteFormModal } from "src/components/modals/invite-modal";
import { useUser } from "src/contexts/user-context";
import { ResourceToPermission, ResourcePermissionGrant } from "src/graphql";
import { ResourcePermission } from "src/hooks/use-has-permission";
import { Row, Column, Box } from "src/ui/box";
import {
  AddUserIcon,
  SyncIcon,
  DestinationIcon,
  ModelIcon,
  SettingIcon,
  OnboardingIcon,
  SourceIcon,
  AudienceIcon,
  ChatIcon,
  ChevronDownIcon,
  DocsIcon,
  ExtensionsIcon,
  SequencesIcon,
} from "src/ui/icons";
import { Link } from "src/ui/link";
import { Menu, MenuOption } from "src/ui/menu";
import { Tooltip } from "src/ui/tooltip";
import { useNavigate } from "src/utils/navigate";
import { switchWorkspace } from "src/utils/workspaces";

import { Permission } from "../permission";

export const Nav: FC = () => {
  const navigate = useNavigate();
  const { onboarding, workspaces, workspace } = useUser();
  const { appNewOnboarding, syncSequencesWorkspace } = useFlags();
  const [openInvite, setOpenInvite] = useState(false);

  const menuOptions: MenuOption[] = useMemo(
    () => [
      {
        title: "Actions",
        label: "Manage workspaces",
        onClick: () => {
          navigate("/workspaces", { slug: false });
        },
        stickToTop: true,
        divider: "bottom",
      },
      ...(workspaces?.map(({ name, id, slug }, index) => ({
        title: index === 0 ? "Workspaces" : undefined,
        label: name,
        onClick: () => switchWorkspace(id, `/${slug}`),
      })) ?? []),
    ],
    [workspaces],
  );

  return (
    <>
      <Column
        sx={{
          height: "100vh",
          position: "sticky",
          top: 0,
          flexDirection: "column",
          width: ["74px", "74px", "74px", "218px"],
          transition: "100ms width ease-in-out",
          bg: "indigo",
          overflowX: "hidden",
          overflowY: "auto",
          pb: 5,
        }}
      >
        <Menu portal options={menuOptions} sx={{ m: 3 }} width="220px">
          <Row
            sx={{
              width: "100%",
              p: "6px",
              borderRadius: 1,
              border: "small",
              borderColor: "whites.2",
              color: "white",
              alignItems: "center",
              transition: "100ms border-color",
              ":hover": {
                borderColor: "whites.3",
              },
            }}
          >
            <Row
              sx={{
                flexShrink: 0,
                alignItems: "center",
                justifyContent: "center",
                bg: "whites.1",
                width: "36px",
                height: "36px",
                borderRadius: 1,
              }}
            >
              <Text sx={{ color: "white", fontWeight: "bold", fontSize: 3 }}>ht</Text>
            </Row>
            <Row
              sx={{ display: ["none", "none", "none", "flex"], alignItems: "center", justifyContent: "space-between", flex: 1 }}
            >
              <Column sx={{ mr: "auto", pr: 1, ml: 2 }}>
                <Text sx={{ textTransform: "uppercase", fontSize: "10px", color: "whites.4", fontWeight: "bold" }}>
                  Workspace
                </Text>
                <Text
                  sx={{
                    fontSize: 0,
                    fontWeight: "semi",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {workspace?.name}
                </Text>
              </Column>

              <ChevronDownIcon color="rgba(255,255,255,.3)" size={18} />
            </Row>
          </Row>
        </Menu>

        <Grid
          gap={2}
          sx={{
            px: 3,
            gridAutoRows: "max-content",
          }}
        >
          {onboarding && <NavLink icon={OnboardingIcon} label={appNewOnboarding ? "Setup" : "Onboarding"} to="/onboarding" />}
          <NavLink
            icon={SyncIcon}
            label="Syncs"
            permissions={[{ resource: ResourceToPermission.Sync, grants: [ResourcePermissionGrant.Read] }]}
            to="/syncs"
          />
          <NavLink
            icon={ModelIcon}
            label="Models"
            permissions={[{ resource: ResourceToPermission.Model, grants: [ResourcePermissionGrant.Read] }]}
            to="/models"
          />
          <NavLink
            icon={AudienceIcon}
            label="Audiences"
            permissions={[{ resource: ResourceToPermission.Audience, grants: [ResourcePermissionGrant.Read] }]}
            to="/audiences"
          />
          <NavLink
            icon={SourceIcon}
            label="Sources"
            permissions={[{ resource: ResourceToPermission.Source, grants: [ResourcePermissionGrant.Read] }]}
            to="/sources"
          />
          <NavLink
            icon={DestinationIcon}
            label="Destinations"
            permissions={[{ resource: ResourceToPermission.Destination, grants: [ResourcePermissionGrant.Read] }]}
            to="/destinations"
          />
          {syncSequencesWorkspace && (
            <NavLink
              icon={SequencesIcon}
              label="Sequences"
              permissions={[{ resource: ResourceToPermission.Sync, grants: [ResourcePermissionGrant.Read] }]}
              to="/sequences"
            />
          )}
          <NavLink icon={ExtensionsIcon} label="Extensions" to="/extensions" />
          <NavLink icon={SettingIcon} label="Settings" to="/settings" />
        </Grid>

        <Grid
          gap={2}
          sx={{
            mt: "auto",
            px: 3,
            gridAutoRows: "max-content",
          }}
        >
          <Permission permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
            <Box onClick={() => setOpenInvite(true)}>
              <NavLink icon={AddUserIcon} label="Invite a teammate" />
            </Box>
          </Permission>

          <FeedbackMenu>
            <NavLink icon={ChatIcon} label="Get in touch" />
          </FeedbackMenu>

          <NavLink newTab icon={DocsIcon} label="Documentation" to={import.meta.env.VITE_DOCS_URL as string} />
        </Grid>
      </Column>
      <InviteFormModal close={() => setOpenInvite(false)} name="Hightouch" open={openInvite} />
    </>
  );
};

const NavLink: FC<
  Readonly<{
    isSelected?: boolean;
    label: string;
    to?: string;
    icon: any;
    permissions?: ResourcePermission[];
    disableContent?: string;
    newTab?: boolean;
    sx?: ThemeUIStyleObject;
  }>
> = ({
  isSelected,
  to,
  label,
  permissions,
  icon: Icon,
  disableContent = "You do not have permission to view this.",
  newTab = false,
  sx = {},
}) => {
  const router = useLocation();
  const { hasPermissions } = useUser();

  const active = typeof isSelected === "undefined" && to ? router.pathname.includes(to) : isSelected;

  const hasPermission = !permissions || hasPermissions(permissions);

  if (hasPermission) {
    return (
      <Link
        newTab={newTab}
        sx={{
          fontWeight: "semi",
          display: "flex",
          flex: 1,
          alignItems: "center",
          p: "10px",
          borderRadius: 1,
          bg: active ? "whites.1" : "transparent",
          border: "small",
          borderColor: "transparent",
          transition: "100ms background-color",
          ":hover": { bg: active ? undefined : "whites.0" },
          justifyContent: ["center", "center", "center", "flex-start"],
          ...sx,
        }}
        to={to}
      >
        <Icon color={active ? "yellow" : "whites.5"} size={20} />
        <Row sx={{ display: ["none", "none", "none", "flex"] }}>
          <Text sx={{ color: active ? "yellow" : "whites.7", ml: 3, whiteSpace: "nowrap" }}>{label}</Text>
        </Row>
      </Link>
    );
  } else {
    return (
      <Tooltip placement="bottom" sx={{ flex: 1 }} text={disableContent}>
        <Box
          sx={{
            fontWeight: "semi",
            display: "flex",
            flex: 1,
            alignItems: "center",
            p: "10px",
            borderRadius: 1,
            bg: active ? "whites.1" : "transparent",
            border: "small",
            borderColor: "transparent",
            transition: "100ms background-color",
            ":hover": { bg: active ? undefined : "whites.0" },
            justifyContent: ["center", "center", "center", "flex-start"],
            ...sx,
          }}
        >
          <Icon color={"whites.3"} size={20} />
          <Row sx={{ display: ["none", "none", "none", "flex"] }}>
            <Text sx={{ color: "whites.4", ml: 3, whiteSpace: "nowrap" }}>{label}</Text>
          </Row>
        </Box>
      </Tooltip>
    );
  }
};
