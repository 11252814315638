import { FC, useEffect, useRef } from "react";

import { useInstantSearch, useCurrentRefinements, useClearRefinements } from "react-instantsearch-hooks-web";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { AnimatedBox } from "src/ui/animations";
import { Row, Column, Box } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { PageSpinner } from "src/ui/loading";
import { Placeholder } from "src/ui/table/placeholder";

import { AlgoliaSearchInput } from "../../search/algolia-search-input";
import { CategoryMenu } from "./category-menu";
import { DestinationDetails } from "./destination-details";
import { DestinationHits } from "./destination-hits";

type DestinationsCatalogProps = {
  destinationDefinitions: DestinationDefinition[];
  error: Error | boolean | null;
  selection: DestinationDefinition | null;
  sidebarTop: number;
  onSelect: (destination: DestinationDefinition | null, hit: any) => void;
};

export const DestinationsCatalog: FC<Readonly<DestinationsCatalogProps>> = ({
  destinationDefinitions,
  error,
  selection,
  onSelect,
  sidebarTop,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const { results, indexUiState } = useInstantSearch();
  const { items: currentRefinements } = useCurrentRefinements();
  const { refine: clearRefinements } = useClearRefinements();
  const currentCategory = currentRefinements?.[0]?.refinements?.[0]?.label ?? "All";
  const headerHeight = 59.5;
  let belowHeaderTop = sidebarTop + headerHeight;

  const detailsScrollTop = () => {
    if (detailsRef.current && detailsRef.current?.scrollTop > 0) {
      detailsRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    analytics.track("Destination Catalog Page Viewed");

    if (headerRef?.current) {
      belowHeaderTop = sidebarTop + headerRef?.current.offsetHeight;
    }
  }, []);

  useEffect(() => {
    if (results?.query.length === 1) {
      analytics.track("Destination Catalog Search Performed", {
        current_category: currentCategory,
      });

      // reset category to "all" when beginning a search
      clearRefinements();
    }
  }, [results?.query.length]);

  // scroll results to top when a category filter occurs
  useEffect(() => {
    if (selection) {
      onSelect(null, null);
    }
    if (results?.query.length < 2 && window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentRefinements?.[0]?.refinements?.[0], results?.query]);

  useEffect(() => {
    if (selection) {
      detailsScrollTop();
    }
  }, [selection]);

  if (error || destinationDefinitions?.length === 0) {
    return (
      <Placeholder
        content={{
          title: "No Destinations",
          body: "Create a destination to get started",
          error: "Destinations failed to load, please try again.",
        }}
        error={Boolean(error)}
      />
    );
  }

  if (!indexUiState?.configure) {
    return <PageSpinner />;
  }

  return (
    <AnimatedBox animate="visible" initial="hidden" sx={{ maxWidth: "1400px" }} variants={containerVariants}>
      <Row
        ref={headerRef}
        sx={{
          position: "sticky",
          top: `${sidebarTop}px`,
          alignItems: "flex-start",
          width: "100%",
          zIndex: 150,
          bg: "white",
        }}
      >
        <Heading sx={{ mt: -9, py: 9, width: "100%", bg: "white" }} variant="h1">
          Destination catalog
        </Heading>
      </Row>
      <Row sx={{ position: "relative", width: "100%", justifyContent: "flex-start", mt: 1 }}>
        <Box
          sx={{
            position: "sticky",
            top: `${belowHeaderTop}px`,
            height: "100%",
            "&::before": {
              content: "''",
              position: "absolute",
              bottom: 0,
              width: "calc(100% - 24px)",
              height: "140px",
              background: "linear-gradient(transparent, 20%, white)",
              zIndex: 1,
            },
          }}
        >
          <Column
            sx={{
              width: "220px",
              maxHeight: `calc(100vh - ${belowHeaderTop + 8}px)`,
              pb: "140px",
              overflowY: "scroll",
            }}
          >
            <CategoryMenu attribute="categories.name" currentCategory={currentCategory} query={results?.query ?? ""} />
          </Column>
        </Box>
        <Column
          sx={{
            mx: 9,
            flex: 1,
            height: "max-content",
            alignItems: "stretch",
            minWidth: selection ? "436px" : "inherit",
            position: "sticky",
            top: `${belowHeaderTop}px`,
            zIndex: 125,
          }}
        >
          <Box sx={{ position: "sticky", top: `${belowHeaderTop}px`, zIndex: 100, width: "calc(100% + 12px)" }}>
            <AlgoliaSearchInput placeholder="Search destinations..." />
            <Box
              sx={{
                height: "28px",
                background: "linear-gradient(#FFF, 90%, rgba(255,255,255,0))",
              }}
            />
          </Box>
          <DestinationHits
            category={currentCategory}
            destinations={destinationDefinitions}
            selection={selection}
            onSelect={onSelect}
          />
        </Column>
        <DestinationDetails
          ref={detailsRef}
          category={currentCategory}
          definition={selection}
          top={belowHeaderTop}
          onSelect={onSelect}
        />
      </Row>
    </AnimatedBox>
  );
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
};
