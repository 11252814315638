import { FC, ReactNode, useState } from "react";

import immutableUpdate from "immutability-helper";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications2";
import { Text, ThemeUIStyleObject } from "theme-ui";

import { AudienceTraitForm } from "src/components/audiences/audience-trait-form";
import { useRelatedAudiencesQuery, useUpdateAudienceMutation } from "src/graphql";
import {
  AdditionalColumn,
  Audience,
  AudienceParent,
  Condition,
  ConditionType,
  EventCondition,
  initialEventCondition,
  initialNumberOfCondition,
  initialPropertyCondition,
  initialSetCondition,
  isTraitCondition,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  SegmentSetCondition,
  TraitCondition,
} from "src/types/visual";
import { Box, Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { EventIcon, PlusIcon, PropertyIcon, SegmentIcon, UsersIcon, WarningIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Modal } from "src/ui/modal";

import { AudienceEventTraitForm } from "../audiences/audience-event-trait-form";
import { ConditionField } from "./visual/condition";

enum SetupLinks {
  RelatedModel = "/audiences/setup/related-models/new",
  Event = "/audiences/setup/events/new",
  Audience = "/audiences/new",
}

type Requirement = { condition: "related model" | "event" | "audience"; to: string };

export interface QueryBuilderProps {
  audience?: Audience;
  parent: AudienceParent | undefined | null;
  conditions: OrCondition[] | undefined;
  setConditions: (conditions: OrCondition[]) => void;
  limit?: number;
  setLimit?: (limit: number) => void;
}

export const QueryBuilder: FC<Readonly<QueryBuilderProps>> = ({ audience, parent, conditions = [], setConditions }) => {
  const { addToast } = useToasts();

  const [requirementMissing, setRequirementMissing] = useState<Requirement | undefined>();
  const { mutateAsync: updateAudience } = useUpdateAudienceMutation({
    onSuccess: () => {
      // prevents audience query from invalidating
    },
  });

  const { data: relatedAudiencesData, isRefetching } = useRelatedAudiencesQuery(
    {
      parentModelId: parent?.id,
      modelId: audience?.id,
    },
    {
      enabled: Boolean(parent),
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60, // 1 minute
    },
  );

  // Used for adding additional columns
  const [selectedCondition, setSelectedCondition] = useState<TraitCondition | EventCondition | null>(null);

  const relationships = parent?.relationships;
  const traits = parent?.traits;
  const columns = parent?.filterable_audience_columns;
  const events = relationships?.filter(({ to_model: { event } }) => Boolean(event));

  const addAdditionalColumn = async (additionalColumn: AdditionalColumn) => {
    if (!audience) {
      return;
    }

    await updateAudience({
      id: audience.id,
      input: {
        visual_query_filter: {
          ...(audience.visual_query_filter || {}),
          additionalColumns: [additionalColumn, ...(audience.visual_query_filter?.additionalColumns || [])],
        },
      },
    });
    addToast("Trait added", { appearance: "success" });
  };

  const addInitialCondition = (condition: Condition) =>
    setConditions([...conditions, { type: ConditionType.Or, conditions: [condition] }]);

  const addOrCondition = (condition: Condition, index: number) => {
    setConditions(immutableUpdate(conditions, { [index]: { conditions: { $push: [condition] } } }));
  };

  const addCondition = (condition: Condition, index?: number) => {
    if (typeof index === "undefined") {
      addInitialCondition(condition);
    } else {
      addOrCondition(condition, index);
    }
  };

  const updateCondition = (index: number) => (subIndex: number) => (updates: any) => {
    setConditions(immutableUpdate(conditions, { [index]: { conditions: { [subIndex]: { $merge: updates } } } }));
  };

  const removeCondition = (index: number) => (subIndex: number) => () => {
    let updatedConditions = immutableUpdate(conditions, { [index]: { conditions: { $splice: [[subIndex, 1]] } } });

    if (updatedConditions?.[index]?.conditions.length === 0) {
      updatedConditions = immutableUpdate(conditions, { $splice: [[index, 1]] });
    }

    setConditions(updatedConditions);
  };

  const resetConditionToAdd = () => {
    setRequirementMissing(undefined);
  };

  const addConditionOptions = (index?: number) => [
    {
      label: "Have a property",
      description: "Filter by a column in the table",
      onClick: () => addCondition(initialPropertyCondition as PropertyCondition, index),
      icon: PropertyIcon,
    },
    {
      label: "Have a relation",
      description: "Filter by a property in a related model",
      onClick: () => {
        if (!relationships || relationships.length === 0) {
          return setRequirementMissing({
            condition: "related model",
            to: SetupLinks.RelatedModel,
          });
        }

        addCondition(initialNumberOfCondition as NumberOfCondition, index);
      },
      icon: UsersIcon,
    },
    {
      label: "Performed an event",
      description: "Filter by whether they have an associated event",
      onClick: () => {
        if (!events || events.length === 0) {
          return setRequirementMissing({
            condition: "event",
            to: SetupLinks.Event,
          });
        }

        addCondition(initialEventCondition as EventCondition, index);
      },
      icon: EventIcon,
    },
    {
      label: "Part of an Audience",
      description: "Filter by whether they are included or not in an Audience",
      onClick: () => {
        if (!relatedAudiencesData?.segments || relatedAudiencesData.segments.length === 0) {
          return setRequirementMissing({
            condition: "audience",
            to: SetupLinks.Audience,
          });
        }

        addCondition(initialSetCondition as SegmentSetCondition, index);
      },
      icon: SegmentIcon,
    },
  ];

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <VStack gap={8} sx={{ pb: 12, pr: 3, fontWeight: "bold", fontSize: 0 }}>
          {conditions.map((condition, index) => (
            <Row key={index} sx={{ width: "100%" }}>
              <Column sx={{ position: "relative" }}>
                <Row sx={{ alignItems: "center", pt: 4 }}>
                  <Row
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "32px",
                      width: "42px",
                      flexShrink: 0,
                      bg: "indigos.1",
                      borderRadius: 1,
                    }}
                  >
                    <Text sx={{ color: "white", fontSize: 0, fontWeight: "bold" }}>
                      {conditions.length < 2 ? "ALL" : "AND"}
                    </Text>
                  </Row>
                  <Box sx={{ height: "2px", bg: "indigos.1", width: "24px" }} />
                </Row>
                <Box sx={{ position: "absolute", top: "48px", height: "100%", bg: "indigos.1", width: "2px", ml: "20px" }} />
              </Column>
              <VStack gap={4}>
                {condition.conditions.map((condition, subIndex) => {
                  return (
                    <>
                      {subIndex > 0 && <Text sx={{ fontSize: 0, fontWeight: "bold", color: "base.4" }}>OR</Text>}
                      <VStack
                        gap={4}
                        sx={{
                          p: 4,
                          bg: "secondaries.0",
                          borderRadius: 1,
                          border: "small",
                          borderColor: "secondaries.1",
                          minWidth: "100%",
                          position: "relative",
                          width: undefined,
                        }}
                      >
                        {audience && (isTraitCondition(condition) || condition.type === ConditionType.Event) && (
                          <Button
                            size="small"
                            sx={{ position: "absolute", top: 4, right: 4 }}
                            variant="gray"
                            onClick={() => setSelectedCondition(condition)}
                          >
                            Add trait
                          </Button>
                        )}
                        <ConditionField
                          audience={audience}
                          columns={columns}
                          condition={condition}
                          events={events}
                          parent={parent}
                          relationships={relationships}
                          traits={traits}
                          onChange={updateCondition(index)(subIndex)}
                          onRemove={removeCondition(index)(subIndex)}
                        />
                      </VStack>
                    </>
                  );
                })}

                <Menu disabled={isRefetching} options={addConditionOptions(index)} width="480px">
                  <Button
                    propagate
                    disabled={isRefetching}
                    iconBefore={!isRefetching && <PlusIcon size={14} />}
                    size="small"
                    variant="gray"
                  >
                    {isRefetching ? <Spinner /> : "OR"}
                  </Button>
                </Menu>
              </VStack>
            </Row>
          ))}
          <Row sx={{ alignItems: "center" }}>
            {conditions.length === 0 && (
              <Text sx={{ fontWeight: "semi", color: "base.5", mr: 4, fontSize: 2 }}>All rows that...</Text>
            )}
            <Menu disabled={isRefetching} options={addConditionOptions()} width="480px">
              <Button
                propagate
                disabled={isRefetching}
                iconBefore={!isRefetching && <PlusIcon size={14} />}
                size="small"
                sx={{ position: "relative" }}
                variant="indigo"
              >
                {isRefetching ? <Spinner /> : "Add condition"}
              </Button>
            </Menu>
          </Row>
        </VStack>
        {selectedCondition &&
          (isTraitCondition(selectedCondition) ? (
            <AudienceTraitForm
              alias=""
              conditions={selectedCondition.property.column.conditions}
              parent={parent}
              title="Add trait"
              trait={traits?.find((t) => t.id === selectedCondition.property.column.traitDefinitionId)}
              onClose={() => {
                setSelectedCondition(null);
              }}
              onSubmit={addAdditionalColumn}
            />
          ) : (
            <AudienceEventTraitForm
              alias=""
              condition={selectedCondition}
              parent={parent}
              title="Add event trait"
              onClose={() => {
                setSelectedCondition(null);
              }}
              onSubmit={addAdditionalColumn}
            />
          ))}
      </Box>

      <Modal
        bodySx={{ pb: 6 }}
        footer={
          <>
            <Button variant="secondary" onClick={resetConditionToAdd}>
              Go back
            </Button>
            <Link newTab to={requirementMissing?.to}>
              <Button onClick={resetConditionToAdd}>Add {requirementMissing?.condition}</Button>
            </Link>
          </>
        }
        header={
          <Row sx={{ alignItems: "center" }}>
            <WarningIcon color="yellow" sx={{ mr: 4 }} />
            <Heading variant="h2">{`No ${pluralize(requirementMissing?.condition ?? "")} found`}</Heading>
          </Row>
        }
        isOpen={Boolean(requirementMissing)}
        sx={{ maxWidth: "400px" }}
        onClose={resetConditionToAdd}
      >
        <Text as="p">
          In order to use this condition you need set up {requirementMissing?.condition === "event" ? "an" : "a"}{" "}
          {requirementMissing?.condition}. Would you like to create one now?
        </Text>
      </Modal>
    </>
  );
};

export const VStack: FC<{ children: ReactNode; sx?: ThemeUIStyleObject; gap: number }> = ({ children, gap, sx = {} }) => (
  <Column sx={{ width: "100%", "& > *:not(:last-child)": { mb: gap }, alignItems: "flex-start", ...sx }}>{children}</Column>
);
